import { useEffect, useState } from 'react';
import Typography from 'src/components/common/typography/Typography';
import Tabs, { TTabItem } from 'src/components/common/tabs/Tabs';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import Header from 'src/components/header/Header';
import { About, Activities, Education, ProfileCard, WorkExperience } from 'src/components/profile';
import { getViewUserProfile } from 'src/store/profile/profile.slice';
import {
  BackNavigation,
  ConditionalComponent,
  InfiniteScroll,
  TDropdownOptions
} from 'src/components/common';
import useUrlParamValue from 'src/hooks/useUrlParamValue/useUrlParamValue';
import { useCopyToClipboard, useGetIdentity, useGoBack } from 'src/hooks';
import { TGetUserStatsRequest } from 'src/services/apiEndpoint.types.ts';
import { getUserStats, incrementNewsFeedPage } from 'src/store/socialMedia/socialMedia.slice';
import {
  CompanyCard,
  CreateCompanyCard,
  InviteNetwork,
  StartupNewsResources
} from 'src/components/cards';

import './viewProfile.scss';
import ResoucesData from './Resources/Resouces';

export type TViewProfileTabs = 'about' | 'work-experience' | 'education' | 'activities';

const ViewProfile = () => {
  const [activeProfileTab, setActiveProfileTab] = useState<TViewProfileTabs>('about');

  const { loading } = useAppSelector<{ modalId: string; loading: boolean }>(
    (store) => store.common
  );
  const { viewUserProfile } = useAppSelector((store) => store.profile);
  const { newsFeedMoreItemsLeft } = useAppSelector((store) => store.socialMedia);

  const dispatch = useAppDispatch();

  // Hooks
  const { paramValue } = useUrlParamValue();
  const { copyToClipboard } = useCopyToClipboard();
  const { getIdentities } = useGetIdentity();
  const handleGoBack = useGoBack();

  const { loggedInUserId, loggedInCompanyId, loggedInUserType } = getIdentities();
  const prevRoute = paramValue({ paramName: 'prev' });
  const viewProfileId = paramValue({ paramName: 'id' });
  const viewerId = loggedInUserType === 'User' ? loggedInUserId : loggedInCompanyId;
  const loadMoreActivities = () => {
    if (newsFeedMoreItemsLeft && !loading && activeProfileTab === 'activities') {
      dispatch(incrementNewsFeedPage(1));
    }
  };

  const {
    about,
    address,
    dob,
    email,
    gender,
    introduction,
    linkedin,
    name,
    phoneNumber,
    profilePic,
    role,
    userExpertise,
    userIndustries,
    id,
    country,
    state,
    city,
    zipCode
  } = viewUserProfile;

  const tabItems: TTabItem[] = [
    {
      key: 'about',
      text: 'About',
      disabled: false,
      component: (
        <About
          about={about}
          address={address}
          dob={dob}
          email={email}
          gender={gender}
          linkedin={linkedin}
          phoneNumber={phoneNumber}
          role={role}
          userExpertise={userExpertise}
          userIndustries={userIndustries}
          country={country}
          state={state}
          city={city}
          zipCode={zipCode}
        />
      )
    },
    {
      key: 'work-experience',
      text: 'Work Experience',
      disabled: false,
      component: <WorkExperience experiences={viewUserProfile?.experience || []} />
    },
    {
      key: 'education',
      text: 'Education',
      disabled: false,
      component: <Education education={viewUserProfile?.education || []} />
    },
    {
      key: 'activities',
      text: 'Activities',
      disabled: false,
      component: (
        <Activities
          userId={viewProfileId || ''}
          viewerId={viewerId}
          viewerType={loggedInUserType}
        />
      )
    },
    {
      key: 'resources',
      text: 'Resources',
      disabled: false,
      component: (
        <ResoucesData userId={viewUserProfile?.id} />
      )
    }
  ];

  const changeViewProfileTab = (value: string) => {
    setActiveProfileTab(value as TViewProfileTabs);
  };

  const copyProfileLink = () => {
    copyToClipboard({ text: `${window.location.host}/view-profile?id=${id}` });
  };

  const profileCardDropdownItems: TDropdownOptions[] = [
    { label: 'Copy profile link', onClick: copyProfileLink }
  ];

  const handleGetUserStats = ({ id }: { id: string }) => {
    const payload: TGetUserStatsRequest = {
      userId: id,
      userType: 'User',
      requestorId: viewerId,
      requestorType: loggedInUserType
    };

    dispatch(getUserStats(payload));
  };

  useEffect(() => {
    const id = viewProfileId || '';
    dispatch(getViewUserProfile({ id }));

    handleGetUserStats({ id });
  }, []);

  return (
    <InfiniteScroll
      className="view-profile"
      onScrollEnd={loadMoreActivities}
      loading={loading}
      moreItemsLeft={newsFeedMoreItemsLeft}
      showFooter={activeProfileTab === 'activities'}
    >
      <Header />
      <div className="view-profile__body">
        {<BackNavigation text="Back" route={prevRoute || '/'} onClick={handleGoBack} />}
        <Typography as="p" variant="subHeading-1" fontWeight="semiBold">
          User&apos;s Profile
        </Typography>
        <div className="view-profile__body__content">
          <div className="view-profile__body__content__body">
            <ProfileCard
              introduction={introduction}
              name={name}
              profilePic={profilePic}
              role={role}
              dropdownItems={profileCardDropdownItems}
              userId={id}
              getUserStats={handleGetUserStats}
            />
            <Tabs
              activeItem={activeProfileTab}
              onTabChange={changeViewProfileTab}
              items={tabItems}
            />
          </div>
          <div className="view-profile__body__content__cards">
            {loggedInUserType === 'User' && (
              <ConditionalComponent
                condition={Boolean(loggedInCompanyId)}
                whenTrue={<CompanyCard selfCompany={true} companyId={loggedInCompanyId} />}
                whenFalse={<CreateCompanyCard />}
              />
            )}

            <InviteNetwork />

            <StartupNewsResources />
          </div>
        </div>
      </div>
    </InfiniteScroll>
  );
};

export default ViewProfile;
