import React, { useEffect, useState } from 'react';
import { TUserTypes } from 'src/constants/user.constants';
import { Row } from 'react-bootstrap';
import { TGetCompany, TGetProgramData } from 'src/services/apiEndpoint.types.ts';
import { useAppSelector } from 'src/store/hooks';
import { httpRequest } from 'src/services/commonApis';
import { Loader } from 'src/components/common';

import cx from './resources.module.scss';
import UploadedResourceBox from './UploadedResourceBox/UploadedResourceBox';

export type TActivities = {
  viewCompanyId: string;
  viewerId: string;
  viewerType: TUserTypes;
};

export default function ResoucesData() {
  const viewCompany: TGetCompany = useAppSelector((store) => store.company.myCompany);
  const viewProgram: TGetProgramData = useAppSelector((store:any) => store.program.viewProgram);
  const [programData, setProgramData] = useState<any>([]);
  const [memberData, setSetMemberData] = useState<any>([]);
  const [resourceData, setResourceData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false)
  async function getCompanyData() {
    const response: any = await httpRequest(`program/list?companyId=${viewCompany?.id}&programStatus=Active`, 'GET', null, 'json', 'program')
    if (response?.data?.programs) {
      setProgramData(response?.data?.programs)
    }
  }
  async function getMemberData() {
    const response: any = await httpRequest(`company/get-members?companyId=${viewCompany?.id}&page=1&pageSize=8`, 'GET', null, 'json', 'company')
    if (response?.data?.users) {
      setSetMemberData(response?.data?.users)
    }
  }
  useEffect(() => {
    if (viewCompany) {
      getCompanyData()
      getMemberData()
      getResources()
    }
  }, [viewCompany]);

  async function getResources() {
    setLoading(true)
    const response = await httpRequest(`resource?companyId=${viewCompany?.id}&programId=${viewProgram?.id}`, 'GET', null, 'json', 'program');
    if (response.body?.length > 0) {
      setResourceData(response.body)
    }
    setLoading(false)
  }

  return (
    <>
    {loading && <Loader />}
      {!loading && <div className={cx.resourceContainer}>
        <Row>
          {/* <Col md={3}>
            <div className={cx.imageUploadBox}>
              <div className={cx.uploadContent}>
                {resourceLoading && <Loader />}
                {!resourceLoading && <label htmlFor='upload' className={cx.uploadLabel}>
                  <input className={cx.uploadInput} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleResourceUpload(e)} type='file' id='upload' accept='' />
                  <PlusIconCircle />
                  <h5 className={cx.uploadTitle}>Upload Resources</h5>
                </label>}
              </div>
            </div>
          </Col> */}
          {resourceData?.map((resourceData: any) => {
            return (
              <UploadedResourceBox key={resourceData?.id} getResources={getResources} resourceData={resourceData} programData={programData} memberData={memberData} />
            )
          })}
        </Row>
      </div>}
    </>
  )
}
