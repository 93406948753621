import { MouseEvent, useMemo, useState } from 'react';
import InputElement from 'src/components/common/formFields/inputElement/InputElement';
import { SearchIcon, SingleChevronIcon } from 'src/components/common/common.icons';
import Button from 'src/components/button/Button';
import useDebounce from 'src/hooks/useDebounce/useDebounce';
import { componentSizeVariantsEnum } from 'src/constants';
import {
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Typography
} from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { useUrlParamValue } from 'src/hooks';
import { resetApplicants } from 'src/store/program/program.slice';

import './searchSubmittedApplications.scss';
import { TSearchSubmittedApplicationsProps } from './searchSubmittedApplications.types';

const ideaHubFiltersDropdownId = 'ideaHubFiltersDropdown';

const SearchSubmittedApplications = ({
  updateParams,
  increaseFetchApplicantsCounter,
  status
}: TSearchSubmittedApplicationsProps) => {
  // Hooks
  const dropdownId: string = useAppSelector((store) => store.common.dropdownId);
  const stages: {
    stageTitle: string;
    stageNumber: number;
    id: string;
  }[] = useAppSelector((store) => store.program.viewSubmittedApplicationStages);
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();

  const query: string = paramValue({ paramName: 'query' }) as string;
  const filterStageTitle: string = paramValue({
    paramName: 'filterStageTitle'
  }) as string;

  const [searchQuery, setSearchQuery] = useState<string>(query || '');

  const handleSearchIdeas = () => {
    updateParams({ param: 'filterString', value: searchQuery });
  };

  useDebounce({ func: handleSearchIdeas, delay: 500, dependency: searchQuery });

  const resetExistingData = () => {
    // Reset data when filters are changed
    dispatch(resetApplicants());
  };

  const ideaFilterDropdownItems: TDropdownOptions[] = useMemo(() => {
    try {
      const items: TDropdownOptions[] = stages?.map((item) => {
        const stateTitle: string = item?.stageTitle;

        return {
          label: stateTitle,
          onClick: () => {
            resetExistingData();
            updateParams({ param: 'filterStageTitle', value: stateTitle });
          },
          ...(filterStageTitle === stateTitle && { state: 'active' })
        };
      });

      items?.unshift({
        label: 'All',
        onClick: () => {
          resetExistingData();
          updateParams({ param: 'filterStageTitle', value: 'All' });
        },
        ...(filterStageTitle === 'All' && { state: 'active' })
      });

      return items;
    } catch {
      return [];
    }
  }, [filterStageTitle, JSON.stringify(stages)]);

  const ideaFilterDropdownProps: TDropdown = {
    id: ideaHubFiltersDropdownId,
    size: 'large',
    alignment: 'left',
    items: ideaFilterDropdownItems
  };

  const openFiltersDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(ideaHubFiltersDropdownId));
    }
  };

  return (
    <div className="search-submitted-applications">
      <form
        className="search-submitted-applications__search"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearchIdeas();
        }}
      >
        <InputElement
          startIcon={<SearchIcon />}
          placeholder="Search by name, email and application number"
          value={searchQuery}
          onChange={(e) => {
            resetExistingData();
            setSearchQuery(e.target.value);
          }}
          variant={componentSizeVariantsEnum.LARGE}
          className="search-submitted-applications__search__input-field"
        />
        <Button
          variant="primary"
          size="small"
          type="submit"
          onClick={increaseFetchApplicantsCounter}
        >
          Search
        </Button>
      </form>

      <div className="search-submitted-applications__filter">
        <Typography as="span" variant="body-2" fontWeight="semiBold">
          Filter:
        </Typography>
        <div className="search-submitted-applications__filter__dropdown">
          <Button
            variant="secondary"
            size="small"
            endIcon={<SingleChevronIcon />}
            onClick={openFiltersDropdown}
          >
            <Typography variant="body-2" fontWeight="semiBold">
              {filterStageTitle || 'All'}
            </Typography>
          </Button>
          <SubMenuModal>
            <Dropdown {...ideaFilterDropdownProps} />
          </SubMenuModal>
        </div>
      </div>
    </div>
  );
};

export default SearchSubmittedApplications;
