import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelfCompanyContext } from 'src/pages/myCompany/MyCompany';
import {
  Card,
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Typography
} from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { MoreOptionsIcon } from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { TDeleteMemberRequest } from 'src/services/apiEndpoint.types.ts';
import { StorageUtils } from 'src/utils';
import { deleteKeyMember } from 'src/store/company/company.slice';

import './keyMemberCard.scss';

export type TKeyMemberCard = {
  id: string;
  profilePic: string;
  name: string;
  role: string;
  description: string;
};

const KeyMemberCard = ({
  profilePic,
  name = '',
  role = '',
  description = '',
  id = ''
}: TKeyMemberCard) => {
  const { dropdownId } = useAppSelector((store) => store.common);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selfCompany = useContext(SelfCompanyContext);

  const handleViewProfile = () => {
    if (id) {
      navigate(`/view-profile?id=${id}&prev=/my-company?tab=key-members`);
    }
  };

  const handleRemoveKeyMember = () => {
    const companyId = StorageUtils.get('companyId') as string

    const payload: TDeleteMemberRequest = {
      companyId,
      userId: id
    };

    dispatch(deleteKeyMember(payload));
  };

  const dropdownItems: TDropdownOptions[] = [{ label: 'View Profile', onClick: handleViewProfile }];

  if (selfCompany) {
    dropdownItems.push({ label: 'Remove user', onClick: handleRemoveKeyMember });
  }

  const dropdownOptions: TDropdown = {
    id: `key-member-card-dropdown-${id}`,
    size: 'large',
    alignment: 'center',
    items: dropdownItems
  };

  const openKeyMemberCardDropdown = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(dropdownOptions.id));
    }
  };
  return (
    <Card className="key-member-card">
      <div className="key-member-card__header">
        <div className="key-member-card__header__user-info">
          <ProfilePicture
            url={profilePic}
            fallback={name[0]}
            className="key-member-card__header__user-info__profile-pic"
          />
          <div className="key-member-card__header__user-info__user">
            <Typography
              as="p"
              variant="body-2"
              fontWeight="semiBold"
              className="key-member-card__header__user-info__user__name"
            >
              {name}
            </Typography>
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="key-member-card__header__user-info__user__role"
            >
              {role}
            </Typography>
          </div>
        </div>
        <div
          className="key-member-card__header__dropdown"
          onClick={(e) => {
            e.stopPropagation();
            openKeyMemberCardDropdown();
          }}
        >
          <MoreOptionsIcon />
          <SubMenuModal>
            <Dropdown {...dropdownOptions} />
          </SubMenuModal>
        </div>
      </div>
      <Typography
        as="p"
        variant="caption"
        fontWeight="regular"
        className="key-member-card__description"
      >
        {description}
      </Typography>
    </Card>
  );
};

export default KeyMemberCard;
