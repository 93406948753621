import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useGetIdentity } from 'src/hooks';
import { TGetInviteNotificationsRequest } from 'src/services/apiEndpoint.types.ts';
import { getAllNotifications } from 'src/store/notifications/notifications.slice';

import './inviteNotifications.scss';

import InviteNotificationCard from '../inviteNotificationCard/InviteNotificationCard';

const InviteNotifications = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();
  const { allNotifications, allNotificationsPageNumber, allNotificationsItemsPerPage } =
    useAppSelector((store) => store.notifications);

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();

  const fetchInviteNotifications = () => {
    const payload: TGetInviteNotificationsRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      limit: allNotificationsItemsPerPage,
      offset: allNotificationsPageNumber
    };

    dispatch(getAllNotifications(payload));
  };

  useEffect(() => {
    fetchInviteNotifications();
  }, [allNotificationsPageNumber]);

  return (
    <div className="all-notifications">
      {allNotifications.map((item, index) => {
        return <InviteNotificationCard {...item} notificationTab="invite" key={index} />;
      })}
    </div>
  );
};

export default InviteNotifications;
