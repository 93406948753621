import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIResponse } from 'src/services/base.api.service';
import {
  TGetProgramDocumentRequest,
  TGetProgramDocumentResponse,
  TUploadApplicationFileAction,
  TUploadApplicationFileResponse,
  TUploadFileToS3Request,
  TUploadFileToS3Response,
  TUploadProgramFileAction,
  TUploadProgramFileResponse
} from 'src/services/apiEndpoint.types.ts';
import { ExternalProgramAPIService } from 'src/services/externalProgram.service';
import { errorMessages } from 'src/constants';

import { initialState } from './externalProgram.slice.initialState';

import { AppDispatch } from '../store';
import { openPopup, startLoading, stopLoading } from '../common/common.slice';

export const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    // Programs

    // View program
    getProgramForExternalUserSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      const programData: any = action.payload;
      console.log(action, 'actionData');

      state.viewProgram = programData;
    },
    getProgramError: () => { },
    resetViewExternalProgram: (state) => {
      state.viewProgram = initialState.viewProgram;
    }
  }
});

// Action creators are generated for each case reducer function
export const { getProgramForExternalUserSuccess, getProgramError, resetViewExternalProgram } =
  programSlice.actions;

export default programSlice.reducer;

const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message;

  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] ||
        message ||
        errorMessages.unknownError,
      popupType: 'error'
    })
  );
};

export const getProgramForExternalUser =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<any> =
        await new ExternalProgramAPIService().getProgram(payload);
      const { status, data } = response;
      if (status === 200) {
        dispatch(getProgramForExternalUserSuccess(data?.data));
      }
      return response;
    } catch (err: any) {
      if (err.code === 'ERR_NETWORK') {
        try {
          const response: APIResponse<any> =
            await new ExternalProgramAPIService().getProgram(payload);
          const { status, data } = response;
          if (status === 200) {
            dispatch(getProgramForExternalUserSuccess(data?.data));
          }
          return response;
        } catch (err: any) {

        }
      }
      dispatch(getProgramError());
    } finally {
      dispatch(stopLoading());
    }
  };

export const uploadFileToS3 =
  (payload: TUploadFileToS3Request) => async (dispatch: AppDispatch) => {
    try {
      const { status }: APIResponse<TUploadFileToS3Response> =
        await new ExternalProgramAPIService().uploadFileToS3({
          s3Key: payload.s3Key,
          data: payload.data,
          includeAuthorizationHeaders: false
        });
      if (status === 200) {
        console.info('success');
      }
    } catch { }
  };

export const getUploadProgramFile =
  (payload: TUploadProgramFileAction) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    const { data: fileData, ...uploadPayload } = payload;

    try {
      const response: APIResponse<TUploadProgramFileResponse> =
        await new ExternalProgramAPIService().uploadProgramFile(uploadPayload);

      const { status, data } = response;
      if (status === 200 && data) {
        const uploadFileToS3Payload: TUploadFileToS3Request = {
          s3Key: data?.data || '',
          data: fileData,
          includeAuthorizationHeaders: false
        };

        dispatch(uploadFileToS3(uploadFileToS3Payload));
      }

      return response;
    } catch {
    } finally {
      dispatch(stopLoading());
    }
  };

export const getUploadApplicationFileExternalUser =
  (payload: TUploadApplicationFileAction) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    const { data: fileData, ...uploadPayload } = payload;

    try {
      const response: APIResponse<TUploadApplicationFileResponse> =
        await new ExternalProgramAPIService().uploadApplicationFile(uploadPayload);

      const { status, data } = response;
      if (status === 200 && data) {
        const uploadFileToS3Payload: TUploadFileToS3Request = {
          s3Key: data?.data || '',
          data: fileData,
          includeAuthorizationHeaders: false
        };

        dispatch(uploadFileToS3(uploadFileToS3Payload));
      }

      return response;
    } catch {
    } finally {
      dispatch(stopLoading());
    }
  };

export const getProgramDocumentExternalUser =
  (payload: TGetProgramDocumentRequest) => async (dispatch: AppDispatch) => {
    try {
      const { status, data }: APIResponse<TGetProgramDocumentResponse> =
        await new ExternalProgramAPIService().getDocument(payload);
      if (status === 200 && data?.data) {
        window.open(data?.data?.presignedUrl);
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };
