/* eslint-disable no-unused-vars */

const errorMessages = {
  'Invalid credentials': 'Email or password is incorrect, please try again',
  'User already exists': 'The entered email address already exists. Please login.',
  'User confirmed!': 'Congratulations! You have successfully signed up for htraction.',
  'Internal server error':
    "Something went wrong on our end. We're working to fix it. Please try again later.",
  'Incorrect data/missing fields': ' Old Password Is Incorrect.',
  // 'Incorrect data/missing fields': 'Please enter valid information and retry submitting the form',
  'User does not exists':
    "Oops! The user doesn't exist on our platform. Please check your email address.",
  'Company already exists for the given user': 'You have already created a Company',
  'Service already exists': 'You have already add this service',
  'Network Error': 'Network Error!',
  unknownError: 'Unknown error has occurred, please try again!'
};

export enum errorMessagesEnum {
  USER_ALREADY_EXISTS = 'User already exists'
}

export default errorMessages;
