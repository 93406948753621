import { Fragment, useEffect, type ReactNode } from 'react';
import { isSupported } from 'firebase/messaging';

import './App.css';
import { Routes } from './routes/routes';
import Popup from './components/common/popup/Popup';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getProfile } from './store/profile/profile.slice';
import { StorageUtils } from './utils';
import PageLoader from './components/common/pageLoader/PageLoader';
import { useForceCreateCompany, useListenToChatNotifications } from './hooks';
import { getCompany } from './store/company/company.slice';
import { userAuthorizationFailure } from './store/auth/auth.slice';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';
import useGetNotifications from './firebase/useGetNotifications';
// import { useLocation } from 'react-router-dom';

const App = (): ReactNode => {
  const dispatch = useAppDispatch();
  // const location = useLocation()

  useForceCreateCompany();

  // Get Notifications
  useGetNotifications();

  const { isAuthorized, isAuthorizationCheckInProgress } = useAppSelector((store) => store.auth);

  useListenToChatNotifications();

  useEffect(() => {
    const userId = StorageUtils.get('userId') as unknown as string;
    const companyId = StorageUtils.get('companyId') as unknown as string;
    if (companyId) {
      dispatch(getCompany({ id: companyId }));
    }

    console.log(userId, companyId, 'userCompanyId');
    
    if (userId) {
      dispatch(getProfile({ id: userId }));
    } else if (!companyId && !userId) {
      // if there is no company and user id, the user can't access any protect routes
      dispatch(userAuthorizationFailure());
    }
  }, []);

  const registerNotificationServiceWorker = () => {
    isSupported()
      .then((supported) => {
        if (supported) {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker
              .register('/firebase-messaging-sw.js')
              .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
              })
              .catch((error) => {
                console.error('Service Worker registration failed:', error);
              });
          }
        } else {
          console.log(
            'This browser does not support the APIs required for Firebase Cloud Messaging.'
          );
        }
      })
      .catch((error) => {
        console.error('Error checking API support: ', error);
      });
  };

  useEffect(() => {
    registerNotificationServiceWorker();
  }, []);

  if (isAuthorizationCheckInProgress) {
    return <PageLoader />;
  }
  return (
    <Fragment>
      <ErrorBoundary>
        <Routes isAuthorized={isAuthorized} />
      </ErrorBoundary>
      <Popup />
    </Fragment>
  );
};

export default App;
