import { Col, Dropdown } from 'react-bootstrap';
import { dotMenu, fileIcon } from 'src/assets/images';
import cx from '../resources.module.scss'
import React from 'react';

export default function UploadedResourceBox({ resourceData }: any) {
  const handleDownload = (url: string) => {
    const link: HTMLAnchorElement | any = document.createElement('a');
    if (link && url) {
      link.href = url;
      link.download = url.split('/').pop(); // Use the file name from the URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return (
    <Col md={3}>
      <div className={`${cx.uploadedBox} ${!resourceData?.type?.includes('image') ? cx.document : ''}`}>
        <img src={resourceData?.type.includes('image') ? resourceData?.url : fileIcon} className={`${cx.icon} ${!resourceData?.type.includes('image') ? cx.docs : ''}`} alt="upload-resource" />
        <div className={cx.dotDropdown}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic-6">
              <img src={dotMenu} alt="dot" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={() => handleDownload(resourceData?.url)}>
                <span className={cx.dotDropInside}>Download</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Col>
  )
}
