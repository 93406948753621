import { useNavigate } from 'react-router-dom';
import { Button, Modal, Typography } from 'src/components/common';
import { hTractionLogoWithText } from 'src/assets/images';

import './applicationSuccessModal.scss';
import { TApplicationSuccessModalProps } from './applicationSuccessModal.types';

export const applicationSuccessModalId = 'applicationSuccessModalI';

const ApplicationSuccessModal = ({
  isModalOpen,
  handleCloseModal
}: TApplicationSuccessModalProps) => {
  // Hooks
  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate('/sign-up');
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleCloseModal}
      className="ext-application-success-modal"
    >
      <img
        src={hTractionLogoWithText}
        alt="htraction logo"
        className="ext-application-success-modal__content__logo"
      />
      <Typography
        as="span"
        variant="subHeading-2"
        fontWeight="medium"
        className="ext-application-success-modal__content__text"
      >
        You have successfully applied to the program! To join{' '}
        <Typography
          as="span"
          variant="subHeading-2"
          fontWeight="medium"
          className="ext-application-success-modal__content__text--highlight"
        >
          htraction
        </Typography>{' '}
        and get the most out of your experience, please{' '}
        <Typography
          as="span"
          variant="subHeading-2"
          fontWeight="medium"
          className="ext-application-success-modal__content__text--highlight"
        >
          sign up
        </Typography>{' '}
        to create an account.
      </Typography>
      <div className="ext-application-success-modal__content__buttons">
        <Button variant="secondary" size="small" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" size="small" onClick={handleSignUp}>
          Sign Up
        </Button>
      </div>
    </Modal>
  );
};

export default ApplicationSuccessModal;
