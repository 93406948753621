import { createContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, TBreadcrumb, TTabItem, Tabs } from 'src/components/common';
import Header from 'src/components/header/Header';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { ProgramDetails } from 'src/components/company/programs/program';
import {
  ApplicationActions,
  ApplicationSections
} from 'src/components/company/programs/viewApplication';
import { TGetApplicationRequest, TGetProgramRequest } from 'src/services/apiEndpoint.types.ts';
import { getApplication, getProgram } from 'src/store/program/program.slice';
import { useAppDispatch } from 'src/store/hooks';

import './viewMyApplication.scss';
import { TViewMyApplicationTabs } from './viewMyApplication.types';

export const ViewApplicationSelf = createContext(false);

const ViewMyApplication = () => {
  // Hooks
  const { paramValue } = useUrlParamValue();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();

  const currentTab: TViewMyApplicationTabs = paramValue({
    paramName: 'tab'
  }) as TViewMyApplicationTabs;
  const applicationId: string = paramValue({ paramName: 'id' }) as string;
  const programId: string = paramValue({ paramName: 'programId' }) as string;

  const handleChangeTab = (value: string) => {
    const params: string = `id=${applicationId}&tab=${value as TViewMyApplicationTabs}`;

    navigate(`/view-my-application?${params}`);
  };

  const handleViewMyProfile = () => {
    navigate('/my-profile');
  };

  const breadCrumbs: TBreadcrumb[] = [
    { label: 'My Profile', url: '', onClick: handleViewMyProfile },
    { label: 'Program Details', url: '' }
  ];
  const handleGetProgram = () => {
    const payload: TGetProgramRequest = {
      programId: programId,
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(getProgram(payload));
  };
  const tabItems: TTabItem[] = useMemo(() => {
    return [
      {
        key: 'programDetails',
        text: 'Program Details',
        disabled: false,
        component: <ProgramDetails />
      },
      {
        key: 'myApplication',
        text: 'My Application',
        disabled: false,
        component: <ApplicationSections />
      }
    ];
  }, []);

  const handleGetApplication = () => {
    const payload: TGetApplicationRequest = {
      applicationId: applicationId,
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(getApplication(payload));
  };

  useEffect(() => {
    handleGetApplication();
    handleGetProgram();
  }, [applicationId]);

  return (
    <ViewApplicationSelf.Provider value={true}>
      <div className="view-my-application">
        <Header />
        <div className="view-my-application__body">
          <Breadcrumbs breadcrumbs={breadCrumbs} />
          <ApplicationActions />
          <Tabs activeItem={currentTab} onTabChange={handleChangeTab} items={tabItems} />
        </div>
      </div>
    </ViewApplicationSelf.Provider>
  );
};

export default ViewMyApplication;
