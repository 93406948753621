import { MouseEvent, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import {
  Card,
  ConfirmModal,
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Tooltip,
  Typography,
  VerticalSeparator
} from 'src/components/common';
import { SelfCompanyContext } from 'src/pages/myCompany/MyCompany';
import {
  useCopyToClipboard,
  useGetIdentity,
  useToggleOpenDropdown,
  useToggleOpenModal
} from 'src/hooks';
import { MoreOptionsIcon } from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TArchiveProgramRequest, TCloseProgramRequest } from 'src/services/apiEndpoint.types.ts';
import { archiveProgram, closeProgram } from 'src/store/program/program.slice';
import { formatDate, textEllipsis } from 'src/utils';
import CreateEditPostModal from 'src/components/posts/createEditPostModal/CreateEditPostModal';

import './programCard.scss';

import { TProgramCardProps } from '..';
const ProgramCard = ({ programMemberView, programType, ...program }: TProgramCardProps) => {
  const {
    id: programId,
    title,
    startDate,
    endDate,
    status,
    applicantCount,
    getProgramList
  } = program;
  // Hooks
  console.log(programId, 'programMemberView')
  const toggleOpenDropdown = useToggleOpenDropdown();
  const toggleOpenModal = useToggleOpenModal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { copyToClipboard } = useCopyToClipboard();
  const isSelfCompany = useContext(SelfCompanyContext);
  const { getIdentities } = useGetIdentity();
  const { modalId, loading }: { modalId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );

  const programDropdownId: string = `program-card-dropdown-${programId}`;
  const closeProgramModalId: string = `closeProgramModal-${programId}`;
  const archiveProgramModalId: string = `archiveProgramModal-${programId}`;
  const shareProgramModalId: string = `shareProgram-${programId}`;

  const { loggedInUserId, loggedInCompanyId, loggedInUserType } = getIdentities();

  // Constants
  const isCloseProgramModalOpen = modalId === closeProgramModalId;
  const isArchiveProgramModalOpen = modalId === archiveProgramModalId;
  const isShareProgramModalOpen = modalId === shareProgramModalId;
  const viewerId = loggedInUserType === 'User' ? loggedInUserId : loggedInCompanyId;

  // Utils
  const titleWitEllipsis = textEllipsis({ text: title, charLimit: 12 });

  const toggleOpenCloseProgramModal = () => {
    toggleOpenModal({ modalId: closeProgramModalId });
  };

  const toggleOpenArchiveProgramModal = () => {
    toggleOpenModal({ modalId: archiveProgramModalId });
  };

  const toggleOpenShareProgramModal = () => {
    toggleOpenModal({ modalId: shareProgramModalId });
  };

  const handleCloseProgram = () => {
    const payload: TCloseProgramRequest = {
      programId
    };

    dispatch(closeProgram(payload)).then(() => {
      getProgramList({ refreshPrograms: true });
    });
  };

  const handleArchiveProgram = () => {
    const payload: TArchiveProgramRequest = {
      programId
    };

    dispatch(archiveProgram(payload)).then(() => {
      getProgramList({ refreshPrograms: true });
    });
  };

  const handleViewProgramDetails = () => {
    if (programMemberView) {
      navigate(`/view-program?id=${programId}&programMemberView=y&tab=programDetails`);
    } else {
      navigate(`/view-program?id=${programId}&tab=programDetails`);
    }
  };
  const goToCreateProgram = () => {
    console.log(program.ProgramDraft, 'progrdaaammm')
    localStorage.setItem('programDetailsFormValues', JSON.stringify(program.ProgramDraft));
    localStorage.setItem('applicationFormSections', JSON.stringify(program.applicationFormDraft));
    navigate(`/create-program?Draft=${programId}`);
  };

  const handleApplyToProgram = () => {
    // navigate(`/apply-to-program?id=${programId}`);
    navigate(`/apply-to-program-external?id=${programId}`);
  };

  const handleToggleOpenDropdown = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggleOpenDropdown({ dropdownId: programDropdownId });
  };

  const programCardDropdownItems: TDropdownOptions[] = [];

  if (status !== 'Closed' && status !== 'Archived') {
    programCardDropdownItems.push({
      label: 'Share as announcement post',
      onClick: toggleOpenShareProgramModal
    });
  }

  programCardDropdownItems.push({
    label: 'Copy application form link',
    onClick: () => {
      // copyToClipboard({ text: `${window.location.host}/apply-to-program?id=${programId}` });
      copyToClipboard({ text: `${window.location.host}/apply-to-program-external?id=${programId}` });
    }
  });

  if (status !== 'Archived') {
    programCardDropdownItems.push({
      label: 'Archive program',
      onClick: toggleOpenArchiveProgramModal
    });
  }

  if (status !== 'Closed' && status !== 'Archived') {
    programCardDropdownItems.push({
      label: 'Close Application',
      onClick: toggleOpenCloseProgramModal
    });
  }

  const programCardDropdownProps: TDropdown = {
    id: programDropdownId,
    size: 'large',
    alignment: 'center',
    items: programCardDropdownItems
  };

  const showApplyButton = useMemo(() => {
    if (isSelfCompany) {
      return false;
    }
    // if (loggedInUserType === 'Company') {
    //   return false;
    // }
    if (status === 'Closed') {
      return false;
    }
    if (status === 'Archived') {
      return false;
    }
    if (programMemberView) {
      return false;
    }
    return true;
  }, [status, isSelfCompany, loggedInUserType, programMemberView]);

  const showViewProgramButton = useMemo(() => {
    if (isSelfCompany) {
      return true;
    }
    if (programMemberView) {
      return true;
    }
    return false;
  }, [status, isSelfCompany, loggedInUserType, programMemberView]);

  return (
    <Card className="program-card">
      <div className="program-card__content">
        <div className="program-card__content__details">
          <div className="program-card__content__details__heading">
            <Tooltip text={title} conditionToDisplay={titleWitEllipsis?.hasEllipsis} limitWidth>
              <Typography
                as="span"
                variant="body-1"
                fontWeight="semiBold"
                className="program-card__content__details__heading__title"
              >
                {titleWitEllipsis?.text}
              </Typography>{' '}
            </Tooltip>
            <VerticalSeparator />
            <Typography
              as="span"
              variant="caption"
              fontWeight="regular"
              className="program-card__content__details__heading__status"
            >
              {status}
            </Typography>
          </div>
          <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            className="program-card__content__details__start"
          >
            Start date: {formatDate({ dateString: startDate })}
          </Typography>{' '}
          <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            className="program-card__content__details__start"
          >
            End date: {formatDate({ dateString: endDate })}
          </Typography>
        </div>
        <div className="program-card__content__stats">
          <div className="program-card__content__stats__applicants">
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="program-card__content__stats__applications__count"
            >
              {applicantCount}
            </Typography>
            {programType === 'Draft'
              ? (
              <Typography as="p" variant="caption" fontWeight="regular">
              Draft
            </Typography>
                )
              : (
            <Typography as="p" variant="caption" fontWeight="regular">
              Applicant!
            </Typography>
                )}
          </div>

          {/* <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            className="program-card__content__stats__status"
          >
            Open or Closed
          </Typography> */}
        </div>
      </div>

      <div className="program-card__footer">
           {programType === 'Draft' && showViewProgramButton
             ? (
                 <Button
            className="program-card__footer__action"
            size="extraSmall"
            variant="secondary"
            onClick={goToCreateProgram}
          >
            Complete Application
          </Button>
               )
             : (
              <Button
            className="program-card__footer__action"
            size="extraSmall"
            variant="secondary"
            onClick={handleViewProgramDetails}
          >
            View Details
          </Button>
               )}
        {showApplyButton && (
          <Button
            className="program-card__footer__action"
            size="extraSmall"
            variant="secondary"
            onClick={handleApplyToProgram}
          >
            Apply
          </Button>
        )}
        {isSelfCompany && (
          <div className="program-card__footer__dropdown" onClick={handleToggleOpenDropdown}>
            <MoreOptionsIcon />
            <SubMenuModal>
              <Dropdown {...programCardDropdownProps} />
            </SubMenuModal>
          </div>
        )}
      </div>
      {isCloseProgramModalOpen && (
        <ConfirmModal
          title="Are you sure you want to close this program?"
          openConfirmModal={isCloseProgramModalOpen}
          onClose={toggleOpenCloseProgramModal}
          onSubmit={handleCloseProgram}
          submitText="Confirm"
          type="error"
          loading={loading}
        />
      )}
      {isArchiveProgramModalOpen && (
        <ConfirmModal
          title=" Are you sure you want to archive this program?"
          openConfirmModal={isArchiveProgramModalOpen}
          onClose={toggleOpenArchiveProgramModal}
          onSubmit={handleArchiveProgram}
          submitText="Archive"
          type="error"
          loading={loading}
        />
      )}
      {isShareProgramModalOpen && (
        <CreateEditPostModal
          title="Create Post"
          companyProfile={true}
          isModalOpen={isShareProgramModalOpen}
          onModalClose={toggleOpenShareProgramModal}
          viewerId={viewerId}
          viewerType={loggedInUserType}
          type="announcement"
          shareProgramMode={true}
          // postRedirectUrl={`${window.location.host}/apply-to-program?id=${programId}`}
          postRedirectUrl={`${window.location.host}/apply-to-program-external?id=${programId}`}
        />
      )}
    </Card>
  );
};

export default ProgramCard;
