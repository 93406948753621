interface header {
  Authorization: any;
  'id-token': any;
  'Content-Type': string;
}

export const httpRequest = async (
  api: string,
  method: string,
  body: any,
  dataType: any,
  type?: string
) => {
  const authorizationToken: any = JSON.parse(localStorage.getItem('AuthenticationResult')!);

  // const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
  const BASE_URL = type === 'program' ? process.env.REACT_APP_API_PROGRAM_ENDPOINT : type === 'company' ? process.env.REACT_APP_API_ENDPOINT : process.env.REACT_APP_LOCALURL
  try {
    if (dataType === 'json') {
      const url = type === 'global' ? api : `${BASE_URL}/${api}`;
      const headers: header | any = type === 'global'
        ? { 'Content-Type': 'application/json' }
        : {
            Authorization: authorizationToken?.data?.AccessToken,
            'id-token': authorizationToken?.data?.IdToken,
            'Content-Type': 'application/json'
          };
      const res = await fetch(url, {
        method: method.toUpperCase(),
        headers,
        body: body ? JSON.stringify(body) : null
      });
      return await res.json();
    } else if (dataType === 'formdata') {
      const res = await fetch(`${BASE_URL}/${api}`, {
        method,
        headers: {
          Authorization: authorizationToken?.data?.AccessToken,
          'id-token': authorizationToken?.data?.IdToken
        },
        body
      });
      return await res.json();
    } else {
      const res = await fetch(`${BASE_URL}/${api}`, {
        method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: body ? JSON.stringify(body) : null
      });
      return await res.json();
    }
  } catch (error) {
    return await error;
  }
};
