import { useEffect } from 'react';
import { Typography } from 'src/components/common';
import { useGetIdentity } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TAppliedProgramsListRequest } from 'src/services/apiEndpoint.types.ts';
import { appliedProgramsList } from 'src/store/program/program.slice';

import './appliedProgramsList.scss';

import AppliedProgramCard from '../appliedProgramCard/AppliedProgramCard';

const AppliedProgramsList = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { appliedProgramsPageNumber, appliedProgramsPerPage, appliedPrograms } = useAppSelector(
    (store) => store.program
  );
  const { getIdentities } = useGetIdentity();

  const { loggedInUserId } = getIdentities();

  const handleGetAppliedPrograms = () => {
    const payload: TAppliedProgramsListRequest = {
      userId: loggedInUserId,
      limit: appliedProgramsPerPage,
      offset: appliedProgramsPageNumber
    };

    dispatch(appliedProgramsList(payload));
  };

  useEffect(() => {
    handleGetAppliedPrograms();
  }, [appliedProgramsPageNumber, appliedProgramsPerPage, loggedInUserId]);

  return (
    <div className="my-profile-applied-programs-list">
      <Typography variant="subHeading-2" fontWeight="semiBold">
        Applied Programs
      </Typography>
      <div className="my-profile-applied-programs-list__programs">
        {appliedPrograms.map((program, index) => (
          <AppliedProgramCard key={index} {...program} />
        ))}
      </div>
    </div>
  );
};

export default AppliedProgramsList;
