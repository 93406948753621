// import { useState, ReactNode, useMemo, useContext } from 'react';
// import { ViewApplicationSelf } from 'src/pages/viewMyApplication/ViewMyApplication';
import React, { useState, ReactNode, useMemo, useEffect } from 'react';
import { Card, LeftMenu, PageLoader, Typography } from 'src/components/common';
import { TGetProgramApplication } from 'src/services/apiEndpoint.types.ts';
import { useAppSelector } from 'src/store/hooks';

import './ApplicationDetails.scss';
import ApplicationSectionView from '../../viewApplication/applicationSectionView/ApplicationSectionView';

import BootstrapModal from 'src/components/common/BootstrapModal/BootstrapModal';
import { useUrlParamValue } from 'src/hooks';
import { httpRequest } from 'src/services/commonApis';
import { Navigate } from 'react-router-dom';
import { TRating, TStageData } from '../applicationRating.types';
interface propsData {
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const ApplicationDetails = ({ loading, setLoading }: propsData) => {
  // Hooks
  const { application: viewApplication }: TGetProgramApplication = useAppSelector((store) => store.program.viewApplication);
  const { paramValue } = useUrlParamValue();
  const stageIdByQuery: string | null = paramValue({ paramName: 'stageId' }) as string;
  const programIdByQuery: string | null = paramValue({ paramName: 'programId' }) as string;
  const mentorEmail: string | null = paramValue({ paramName: 'mentorEmail' }) as string;
  // State
  const [show, setShow] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<number>(0);
  const [stageData, setStageData] = useState<TStageData | null>(null);
  const [ratingData, setRatingData] = useState<TRating[]>([]);
  const handleClose = () => setShow(false)
  const programApplicationSections = useMemo(() => {
    const items = viewApplication?.response?.sections.map((section) => {
      const { sectionTitle, sectionItems } = section;
      const data = sectionItems.map((item) => {
        return { label: item?.label, value: item?.response, type: item?.type, files: item?.files };
      });

      return {
        key: sectionTitle,
        label: sectionTitle,
        component: <ApplicationSectionView sectionTitle={sectionTitle} data={data} />
      };
    });

    const feedbackData = viewApplication?.feedback?.map((item) => {
      return { label: `Feedback on advancing ${item?.stageTitle}`, value: item?.feedback };
    });
    items.push({
      key: 'Feedback',
      label: 'Rate Application',
      component: <ApplicationSectionView sectionTitle={'Feedback'} data={feedbackData} />
    });
    return items;
  }, [JSON.stringify(viewApplication)]);

  const currentIdeaDetailsComponent: ReactNode = useMemo(() => {
    try {
      const section = programApplicationSections[activeSection];
      return section?.component;
    } catch {
      return <>-</>;
    }
  }, [activeSection, viewApplication]);

  async function fetchStageData() {
    const stageResponse = await httpRequest(
      `program/stage?programId=${programIdByQuery}&stageId=${stageIdByQuery}`,
      'GET',
      null,
      'json',
      'program'
    );

    if (stageResponse?.data?.length > 0) {
      const stageData = stageResponse?.data?.find((stage: TStageData) =>
        stageIdByQuery ? stage?.id === stageIdByQuery : false
      );
      setStageData(stageData);
    }
    setLoading(false)
  }

  async function fetchEvaluationData() {
    const evaluationResponse = await httpRequest(
      `application/getEvaluation?programId=${programIdByQuery}&stageId=${stageIdByQuery}&applicationId=${viewApplication?.id}`,
      'GET',
      null,
      'json',
      'program'
    );

    if (evaluationResponse?.data?.length > 0) {
      setRatingData(evaluationResponse?.data);
    }
    setLoading(false)
  }

  useEffect(() => {
    if (stageIdByQuery && programIdByQuery && viewApplication?.id && viewApplication?.id !== '0' && viewApplication?.id !== '') {
      fetchEvaluationData()
      fetchStageData()
    }
  }, [stageIdByQuery, programIdByQuery, viewApplication?.id]);
  if (loading) {
    return <PageLoader message={'Loading...'} key={'loading'} />
  }

  if (ratingData?.some((rating: TRating) => rating?.mentorEmail === mentorEmail)) {
    return <Navigate to='/success' />
  }
  return (
    <>
      {show && <BootstrapModal
        show={show}
        handleClose={handleClose}
        title={''}
        stage={stageData}
        type={'edit'}
        ratingData={ratingData}
      />}
      <div className="view-program-application-sections">
        <Card className="view-program-application-sections__menu">
          {programApplicationSections.map(({ label, key }, index) => {
            const isActive: boolean = activeSection === index;

            return (
              <LeftMenu
                key={key}
                state={isActive ? 'active' : 'default'}
                onClick={() => {
                  if (key === 'Feedback') {
                    if (viewApplication) {
                      setShow(true)
                    }
                  } else {
                    setActiveSection(index);
                  }
                }}
                className="view-program-application-sections__menu__item"
              >
                <Typography as="p" variant="body-2" fontWeight="medium">
                  {label}
                </Typography>
              </LeftMenu>
            );
          })}
        </Card>
        <div className="view-program-application-sections__component">
          {currentIdeaDetailsComponent}
        </div>
      </div>
    </>
  );
};

export default ApplicationDetails;
