import { Card, Typography } from 'src/components/common';
import { Form, Formik } from 'formik';
import { PersonIcon, MailIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { colors, componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { useGetIdentity } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { openPopup } from 'src/store/common/common.slice';

import './applicantDetails.scss';
import applicantDetailsValidationSchema from './applicantDetails.validationSchema';
import { TApplicantDetailsProps, TApplicationDetailsFormValues } from './applicantDetails.types';

const ApplicantDetails = ({ updateApplicantDetails }: TApplicantDetailsProps) => {
  const initialValues: TApplicationDetailsFormValues = {
    name: '',
    email: '',
    mobile: ''
  };

  // Hooks
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();
  const { myCompany } = useAppSelector((store) => store.company);

  // Constants
  const { loggedInUserType } = getIdentities();

  const handleSubmit = (values: TApplicationDetailsFormValues) => {
    console.log(values, 'valuesvalues')
    if (loggedInUserType === 'Company') {
      dispatch(
        openPopup({
          popupMessage: `You cannot apply to this program because you are an ${myCompany?.type}`,
          popupType: 'error'
        })
      );
    } else {
      updateApplicantDetails(values);
    }
  };

  return (
    <Card className="apply-program-applicant-details">
      <Typography
        as="p"
        variant="body-1"
        fontWeight="semiBold"
        style={{ color: colors.neutral[1000] }}
      >
        Application Form
      </Typography>
      <Typography
        as="p"
        variant="caption"
        fontWeight="regular"
        style={{ color: colors.neutral[1000] }}
      >
        To apply for this program, please fill out the application form below. Make sure all fields
        are filled in correctly.
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={applicantDetailsValidationSchema}
      >
        {() => {
          return (
            <Form className="apply-program-applicant-details__form" noValidate>
              <InputField
                label="Full Name"
                id="name"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Full Name"
                startIcon={<PersonIcon />}
                type="text"
                required
              />
              <InputField
                label="Email "
                id="email"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Email Address"
                startIcon={<MailIcon />}
                type="email"
                required
              />
               <InputField
                label="Mobile "
                id="mobile"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Phone number"
                // startIcon={<TextIcon />}
                type="number"
                required
              />
              <Button variant="primary" size="small" type="submit">
                Continue
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default ApplicantDetails;
