import createProgramDetailsSchema from '../createProgramDetailsSchema';
import { TCustomField, TProgramDetailsField, TUseUpdateErrors } from '../programDetails.types';
import validateForm from '../validateForm';

const useUpdateErrors = () => {
  const updateErrors = async ({ values, customFields, updateErrorsState }: TUseUpdateErrors) => {
    const { title, startDate, endDate, stages, stageTitles, ...programDetailsFields } = values;

    const programDetails: TProgramDetailsField[] = [];

    try {
      Object.keys(programDetailsFields).forEach((key) => {
        const field = programDetailsFields[key as keyof typeof programDetailsFields];
        programDetails.push(field);
      });
    } catch {}

    const customFieldValues: Record<string, string> = {};

    Object.keys(programDetailsFields).forEach((fieldKey) => {
      const field: TCustomField =
        programDetailsFields[fieldKey as keyof typeof programDetailsFields];
      const id: string = field?.id;

      customFieldValues[id] = field?.values || '';

      if (field?.type !== 'label') {
        customFieldValues[`${id}-label`] = field?.label || '';
      }
    });

    const schema = createProgramDetailsSchema({ fields: customFields });

    const errors = await validateForm({
      values: { title, startDate, endDate, stages, ...customFieldValues },
      schema,
      updateErrors: updateErrorsState
    });

    return errors?.length;
  };

  return updateErrors;
};

export default useUpdateErrors;
