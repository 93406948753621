/* eslint-disable multiline-ternary */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { textFormatter } from 'src/utils';
import Header from 'src/components/header/Header';
import Typography from 'src/components/common/typography/Typography';
import CreatePost from 'src/components/posts/createPost/CreatePost';
import FilterPosts, {
  TPostFilterOptions,
  TPostFilters
} from 'src/components/posts/filterPosts/FilterPosts';
import { TDropdownOptions } from 'src/components/common/dropdown/Dropdown';
import ViewPost from 'src/components/posts/viewPost/ViewPost';
import { DashboardCard } from 'src/components/ideaHub';
import {
  clearNewsFeed,
  dontRecommentPost,
  follow,
  getNewsFeed,
  getNewsFeedFollowing,
  getNewsFeedPopular,
  incrementNewsFeedPage,
  unFollow
} from 'src/store/socialMedia/socialMedia.slice';
import { ConditionalComponent, InfiniteScroll } from 'src/components/common';
import { useCopyToClipboard, useGetIdentity } from 'src/hooks';
import {
  TDontRecommendRequest,
  TFollowRequest,
  TGetNewsFeedRequest,
  TPost,
  TUnFollowRequest
} from 'src/services/apiEndpoint.types.ts';
import { TUserTypes } from 'src/constants/user.constants';
import { openPopup } from 'src/store/common/common.slice';
import {
  CompanyCard,
  CreateCompanyCard,
  InviteNetwork,
  StartupNewsResources
} from 'src/components/cards';

import './dashboard.scss';

const Dashboard = () => {
  const [filter, setFilter] = useState<TPostFilters>('latest');
  const [filterApplied, setFilterApplied] = useState<boolean>(false);

  const navigate = useNavigate();

  const { profile } = useAppSelector((store) => store.profile);
  const newsFeed = useAppSelector((store) => store.socialMedia.newsFeed);
  const loading: boolean = useAppSelector<boolean>((store) => store.common.loading);
  const { newsFeedItemsPerPage, newsFeedPageNumber, newsFeedMoreItemsLeft } = useAppSelector(
    (store) => store.socialMedia
  );
  const { myCompany } = useAppSelector((store) => store.company);

  // Hooks
  const dispatch = useAppDispatch();
  const { copyToClipboard } = useCopyToClipboard();
  const { getIdentities } = useGetIdentity();

  const { loggedInUserId, loggedInCompanyId, loggedInUserType } = getIdentities();

  const viewerId = loggedInUserType === 'User' ? loggedInUserId : loggedInCompanyId;

  const redirectToViewPost = ({
    postId,
    ownerId,
    post
  }: {
    postId: string;
    ownerId: string;
    post: TPost;
  }) => {
    const creatorType = post?.userType[0] as TUserTypes;

    let isOwner = false;

    if (creatorType === 'Company' && loggedInUserType === 'User') {
      // Useful when the user has created this post using their alternate company profile
      isOwner = false;
    } else {
      isOwner = creatorType === 'User' ? ownerId === loggedInUserId : ownerId === loggedInCompanyId;
    }

    const postRoute = isOwner ? 'view-post-owned' : 'view-post';

    navigate(`/${postRoute}?postId=${postId}&viewAs=${loggedInUserType}`);
  };

  const copyPostLink = (postId: string) => {
    copyToClipboard({ text: `${window.location.host}/view-post?postId=${postId}` });
  };

  const filterOptions: TPostFilterOptions[] = [
    { value: 'latest', label: 'Latest' },
    { value: 'popular', label: 'Popular' },
    { value: 'following', label: 'Following' }
  ];

  const updateFilter = (value: TPostFilters) => {
    setFilterApplied(true);
    dispatch(clearNewsFeed());

    setFilter(value);
  };

  const fetchNewsFeedItems = ({ reload = false }: { reload?: boolean }) => {
    if (reload) {
      dispatch(clearNewsFeed());
    }

    const payload: TGetNewsFeedRequest = {
      userId: viewerId,
      pageNumber: reload ? 1 : newsFeedPageNumber,
      postsPerPage: newsFeedItemsPerPage,
      userType: loggedInUserType
    };

    switch (filter) {
      case 'latest': {
        dispatch(getNewsFeed(payload));
        break;
      }
      case 'popular': {
        dispatch(getNewsFeedPopular(payload));
        break;
      }
      case 'following': {
        dispatch(getNewsFeedFollowing(payload));
        break;
      }
      default: {
        dispatch(getNewsFeed(payload));
        break;
      }
    }
  };

  const loadMoreItems = () => {
    if (newsFeedMoreItemsLeft && !loading) {
      dispatch(incrementNewsFeedPage(1));
    }
  };

  const handleFollowCompany = ({ post, following }: { post: TPost; following: boolean }) => {
    const followUserType = post?.userType[0] as TUserTypes;
    const followUserId = post?.user?.id;
    if (following) {
      const payload: TUnFollowRequest = {
        userId: viewerId,
        userType: loggedInUserType,
        followUserId,
        followUserType
      };

      dispatch(unFollow(payload)).then(() => {
        dispatch(
          openPopup({
            popupMessage: `You have stopped following ${post?.user?.name}`,
            popupType: 'success'
          })
        );
      });
    } else {
      const payload: TFollowRequest = {
        userId: viewerId,
        userType: loggedInUserType,
        followUserId,
        followUserType
      };

      dispatch(follow(payload));
    }
  };

  const handleDontRecommendPost = (postId: string) => {
    const payload: TDontRecommendRequest = {
      userId: viewerId,
      userType: loggedInUserType,
      postId
    };

    dispatch(dontRecommentPost(payload));
  };

  useEffect(() => {
    if (filterApplied) {
      fetchNewsFeedItems({ reload: false });
    }
    return () => {
      dispatch(clearNewsFeed());
    };
  }, [filter]);

  useEffect(() => {
    if (!loading) {
      fetchNewsFeedItems({ reload: false });
    }
  }, [newsFeedPageNumber]);

  return (
    <InfiniteScroll
      className="dashboard"
      onScrollEnd={loadMoreItems}
      loading={loading}
      moreItemsLeft={newsFeedMoreItemsLeft}
    >
      <Header />
      <div className="dashboard__body">
        <div className="dashboard__body__feed">
          <Typography as="h3" variant="heading-3" fontWeight="bold">
            Hello, {textFormatter({ value: profile.name || myCompany?.name })}&#33;
          </Typography>
          {loggedInUserType === 'User' && <DashboardCard />}
          {/*
           ** Create post
           */}
          <CreatePost
            refetchPost={() => {
              fetchNewsFeedItems({ reload: true });
            }}
            companyProfile={Boolean(loggedInUserType === 'Company')}
            viewerId={viewerId}
            viewerType={loggedInUserType}
          />
          {/*
           ** Filter posts
           */}
          <FilterPosts
            filterOptions={filterOptions}
            filter={filter}
            setFilter={updateFilter}
            reloadFeed={fetchNewsFeedItems}
          />
          {/*
           ** List posts
           */}
          <div className="dashboard__body__feed__items">
            {newsFeed.map((post: TPost, index) => {
              const isNotOwner: boolean = post?.user?.id !== viewerId;
              const postId = post?.post?.id;
              const isAnnouncement = post?.post?.isAnnouncement;
              const following = post?.followUserOrCompany;

              const postCardDropdownItems: TDropdownOptions[] = [
                {
                  label: `Copy ${isAnnouncement ? 'announcement' : 'post'} link`,
                  onClick: () => {
                    copyPostLink(postId);
                  }
                },
                {
                  label: `View ${isAnnouncement ? 'announcement' : 'post'}`,
                  onClick: () => {
                    dispatch(clearNewsFeed());
                    redirectToViewPost({ postId, ownerId: post?.user?.id, post });
                  }
                } // if logged in user is owner of the post -> redirect to viewOwnPost
              ];

              if (isNotOwner && isAnnouncement) {
                postCardDropdownItems.push({
                  label: `${following ? 'Unfollow' : 'Follow'} company name`,
                  onClick: () => handleFollowCompany({ post, following })
                });
              }

              if (isNotOwner) {
                postCardDropdownItems.push({
                  label: 'Don’t recommend',
                  onClick: () => handleDontRecommendPost(postId)
                });
              }

              return (
                <ViewPost
                  key={index}
                  dropdownOptions={postCardDropdownItems}
                  expandPost={true}
                  post={post}
                  viewerId={viewerId}
                  viewerType={loggedInUserType}
                />
              );
            })}
          </div>
        </div>
        <div className="dashboard__cards">
          {loggedInUserType === 'User' && (
            <ConditionalComponent
              condition={Boolean(loggedInCompanyId)}
              whenTrue={<CompanyCard selfCompany={true} companyId={loggedInCompanyId} />}
              whenFalse={<CreateCompanyCard />}
            />
          )}

          <InviteNetwork />

          <StartupNewsResources />
        </div>
      </div>
    </InfiniteScroll>
  );
};

export default Dashboard;
