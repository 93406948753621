import React, { useState } from 'react';
import Label from 'src/components/common/formFields/label/Label';
import { useGetFormikField } from 'src/hooks';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';

import './previewField.scss';
import { TPreviewOptionsFieldProps } from './previewField.types';

const TableField = ({ id, label, options, type, required }: TPreviewOptionsFieldProps) => {
  const { getFormikField } = useGetFormikField();
  const [field, fieldMetaData, helpers] = getFormikField({ id: id || '' });

  const showErrorMessage = fieldMetaData?.touched && fieldMetaData?.error;

  // Initial state for table rows
  const [rows, setRows] = useState<Record<string, string>[]>(field.value || [{}]);

  const handleAddRow = () => {
    setRows([...rows, {}]);
  };

  const handleRemoveRow = (index: number) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    helpers?.setValue(updatedRows); // Update Formik value
  };

  const handleInputChange = (index: number, columnName: string, value: string) => {
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [columnName]: value } : row
    );
    setRows(updatedRows);
    helpers?.setValue(JSON.stringify(updatedRows)); // Update Formik value
  };

  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__field">
        <Label required={required}>{label}</Label>
        {options && options?.length > 0 && type === 'tableColumns' && (
          <div className="dynamic-field__table">
            <table className="styled-table">
              <thead>
                <tr>
                  {options.map((option, index) => (
                    <th key={index}>{option}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {options.map((option, colIndex) => (
                      <td key={colIndex}>
                        <input
                          type="text"
                          className="styled-input"
                          name={`${label}_${rowIndex}_${option}`}
                          value={row[option] || ''}
                          onChange={(e) => handleInputChange(rowIndex, option, e.target.value)}
                        />
                      </td>
                    ))}
                    <td>
                      <button
                        type="button"
                        className="action-button"
                        onClick={() => handleRemoveRow(rowIndex)}
                        disabled={rows.length === 1}
                      >
                        -
                      </button>
                      {rowIndex === rows.length - 1 && (
                        <button type="button" className="action-button" style={ { margin: '0px 10px' } } onClick={handleAddRow}>
                          +
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {showErrorMessage && <ErrorMessage message={fieldMetaData?.error} />}
    </div>
  );
};

export default TableField;
