/* eslint-disable no-unneeded-ternary */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { Breadcrumbs, InfiniteScroll, TBreadcrumb, TTabItem, Tabs } from 'src/components/common';
import Header from 'src/components/header/Header';

import {
  ProgramActions,
  ProgramDetails,
  ProgramDiscussion,
  ProgramMembers,
  SubmittedApplications
} from 'src/components/company/programs/program';

import { TGetProgramRequest } from 'src/services/apiEndpoint.types.ts';

import {
  getProgram,
  incrementApplicantsPage,
  incrementProgramMembersPage,
  resetViewProgram
} from 'src/store/program/program.slice';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';

import { TViewProgramTabs } from './viewProgram.types';

import Evaluation from 'src/components/company/programs/program/Evaluation/Evaluation';
import './viewProgram.scss';
import ResoucesData from 'src/components/company/programs/program/Resources/Resouces';

const ViewProgram = () => {
  // Hooks
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { viewProgram, moreApplicantsLeft, moreProgramMembersLeft } = useAppSelector(
    (store) => store.program
  );
  const loading: boolean = useAppSelector((store) => store.common.loading);

  const currentTab: TViewProgramTabs = paramValue({ paramName: 'tab' }) as TViewProgramTabs;
  const programId: string = paramValue({ paramName: 'id' }) as string;
  console.log(programId, 'programIdprogramId')

  const { loggedInUserType, loggedInCompanyId, authenticatedId } = getIdentities();

  const handleChangeTab = (value: string) => {
    const params: string = `id=${programId}&tab=${value as TViewProgramTabs}`;

    navigate(`/view-program?${params}`);
  };

  const handleViewCompany = () => {
    const viewSelfCompany: boolean =
      loggedInUserType === 'Company' && loggedInCompanyId === viewProgram?.companyId;
    navigate(
      viewSelfCompany
        ? '/my-company?tab=programs'
        : `/view-company?id=${viewProgram?.companyId}&tab=programs`
    );
  };

  const breadCrumbs: TBreadcrumb[] = [
    { label: 'Company Profile', url: '', onClick: handleViewCompany },
    { label: 'Program Details', url: '' }
  ];
  const handleGetProgram = () => {
    console.log(programId, 'parseInt(programId)')
     const payload: TGetProgramRequest = {
       programId: programId,
       userId: authenticatedId,
       userType: loggedInUserType
     };
 
     dispatch(getProgram(payload));
   };
  const tabItems: TTabItem[] = [
    {
      key: 'programDetails',
      text: 'Program Details',
      disabled: false,
      component: <ProgramDetails />
    },
    {
      key: 'submittedApplications',
      text: 'Submitted Applications',
      disabled: false,
      component: <SubmittedApplications programId={programId} />
    },
    {
      key: 'discussion',
      text: 'Discussion',
      disabled: false,
      component: <ProgramDiscussion programId={programId} companyId={viewProgram?.companyId} />
    },
    {
      key: 'programMembers',
      text: 'Program Members',
      disabled: false,
      component: <ProgramMembers companyId={viewProgram?.companyId} />
    },
    {
      key: 'evaluation',
      text: 'Evaluation Criteria',
      disabled: false,
      component: <Evaluation companyId={viewProgram?.companyId} />
    },
    {
      key: 'resources',
      text: 'Resources',
      disabled: false,
      component: <ResoucesData />
    }
  ];

  const loadMoreItems = () => {
    switch (currentTab) {
      case 'submittedApplications': {
        if (moreApplicantsLeft && !loading) {
          dispatch(incrementApplicantsPage(1));
        }
        break;
      }
      case 'programMembers': {
        if (moreProgramMembersLeft && !loading) {
          dispatch(incrementProgramMembersPage(1));
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const moreItemsLeft =
    currentTab === 'submittedApplications'
      ? moreApplicantsLeft
      : currentTab === 'programMembers'
        ? moreProgramMembersLeft
        : false;
  const showFooter =
    currentTab === 'submittedApplications' || currentTab === 'programMembers' ? true : false;

  useEffect(() => {
    handleGetProgram();

    return () => {
      dispatch(resetViewProgram());
    };
  }, []);

  return (
    <InfiniteScroll
      className="view-program"
      onScrollEnd={loadMoreItems}
      loading={loading}
      moreItemsLeft={moreItemsLeft}
      showFooter={showFooter}
      showLoader={true}
    >
      <Header />
      <div className="view-program__body">
        <Breadcrumbs breadcrumbs={breadCrumbs}/>
        <ProgramActions
          status={viewProgram?.status}
          programId={programId}
          handleGetProgram={handleGetProgram}
          companyId={viewProgram?.companyId}
        />
        <Tabs activeItem={currentTab} onTabChange={handleChangeTab} items={tabItems} />
      </div>
    </InfiniteScroll>
  );
};

export default ViewProgram;
