import './applyProgramProgramDetails.scss';

import { ProgramDetails } from '../../program';

const ApplyProgramProgramDetails = () => {
  return (
    <div className="apply-program-program-details">
      <ProgramDetails />
    </div>
  );
};

export default ApplyProgramProgramDetails;
