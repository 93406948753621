import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TGetCompany } from 'src/services/apiEndpoint.types.ts';
import { useAppSelector } from 'src/store/hooks';
import { httpRequest } from 'src/services/commonApis';
import { Loader } from 'src/components/common';

import cx from './resources.module.scss';
import UploadedResourceBox from './UploadedResourceBox/UploadedResourceBox';

export type TActivities = {
  userId: string | null
};

export default function ResoucesData({ userId }: TActivities) {
  const viewCompany: TGetCompany = useAppSelector((store) => store.company.myCompany);
  const [resourceData, setResourceData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    if (viewCompany) {
      getResources()
    }
  }, [viewCompany]);

  async function getResources() {
    setLoading(true)
    const response = await httpRequest(`resource?companyId=${viewCompany?.id}`, 'GET', null, 'json', 'program');
    if (response.body?.length > 0) {
      setResourceData(response.body)
    }
    setLoading(false)
  }

  const filterResources = resourceData?.filter((resource: any) => !resource?.isPrivate || resource?.membersId?.includes(`${userId}`))

  return (
    <>
      {loading && <Loader />}
      {!loading && filterResources?.length < 1 && (
        <div className={cx.resourceContainer}>
          <Row>
            <Col md={12}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontSize: '14px', margin: '50px auto', display: 'block' }}>No Resource found</p>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {!loading && <div className={cx.resourceContainer}>
        <Row>
          {filterResources?.map((resourceData: any) => {
            return (
              <UploadedResourceBox key={resourceData?.id} resourceData={resourceData} />
            )
          })}
        </Row>
      </div>}
    </>
  )
}
