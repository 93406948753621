import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { TProgramListRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { programList, resetPrograms } from 'src/store/program/program.slice';
import { Separator, Typography } from 'src/components/common';
import Button from 'src/components/button/Button';
import { SelfCompanyContext } from 'src/pages/myCompany/MyCompany';

import { TFilterProgram, TProgramFilterType, TProgramType, TProgramsProps } from './programs.types';
import './programs.scss';

import CreateProgramCard from '../createProgramCard/CreateProgramCard';
import ProgramCard from '../programCard/ProgramCard';
import ProgramFilters from '../programFilters/ProgramFilters';

const Programs = ({ companyId }: TProgramsProps) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const { paramValue } = useUrlParamValue();
  const navigate = useNavigate();
  const { programsPageNumber, programsPerPage, programs } = useAppSelector(
    (store) => store.program
  );

  console.log(programs, 'programsprograms')
  const { isUserKeyMember } = useAppSelector((store) => store.company.viewCompany);
  const dispatch = useAppDispatch();
  const selfCompany = useContext(SelfCompanyContext);

  // React state
  const [userProgramFilter, setUserProgramFilter] = useState<'All' | 'Access'>('All');

  const { loggedInUserType, loggedInUserId } = getIdentities();

  const programListParams = useMemo(() => {
    const programType: TProgramType = (paramValue({ paramName: 'programType' }) ||
      'Active') as TProgramType;
    const filterProgram: TFilterProgram = (paramValue({ paramName: 'filterProgram' }) ||
      'All') as TFilterProgram;

    return { programType, filterProgram };
  }, [window.location.search]);

  const updateParams = ({ param, value }: { param: TProgramFilterType; value: string }) => {
    let path = '';

    if (selfCompany) {
      path = `${window.location.pathname}?tab=programs&${param}=${value}`;
    } else {
      path = `${window.location.pathname}?id=${companyId}&tab=programs&${param}=${value}`;
    }

    navigate(path);
  };

  const getProgramList = ({ refreshPrograms }: { refreshPrograms: boolean }) => {
    const { programType } = programListParams;
    // const { filterProgram } = programListParams;
    const payload: TProgramListRequest = {
      companyId,
      limit: programsPerPage,

      offset: refreshPrograms ? 1 : programsPageNumber,
      ...(loggedInUserType === 'User' && loggedInUserId && { userId: loggedInUserId }),
      ...(programType && { programStatus: programType }),
      ...(userProgramFilter && loggedInUserType === 'User' && { filterProgram: userProgramFilter })
    };

    dispatch(programList(payload));
  };

  useEffect(() => {
    getProgramList({ refreshPrograms: false });
  }, [programsPageNumber, programsPerPage, window.location.search, userProgramFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetPrograms());
    };
  }, []);
  const { programType } = programListParams;
  return (
    <div className="company-programs">
      <div className="company-programs__header">
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Programs
        </Typography>
        {loggedInUserType === 'User' && isUserKeyMember && <Separator />}
        {loggedInUserType === 'Company' && <ProgramFilters updateParams={updateParams} />}
        {loggedInUserType === 'User' && isUserKeyMember && (
          <div className="company-programs__header__buttons">
            <Button
              size="small"
              className="company-programs__header__buttons__all"
              state={userProgramFilter === 'All' ? 'active' : 'default'}
              variant="tertiary"
              onClick={() => {
                setUserProgramFilter('All');
              }}
            >
              All Programs
            </Button>
            <Button
              size="small"
              className="company-programs__header__buttons__access"
              state={userProgramFilter === 'Access' ? 'active' : 'default'}
              variant="tertiary"
              onClick={() => {
                setUserProgramFilter('Access');
              }}
            >
              Access Programs
            </Button>
          </div>
        )}
      </div>
      <div className="company-programs__programs">
        {loggedInUserType === 'Company' && selfCompany && <CreateProgramCard />}
        {programs.map((program, index) => (
          <ProgramCard
            key={index}
            {...program}
            getProgramList={getProgramList}
            programType = {programType}
            programMemberView={userProgramFilter === 'Access'}
          />
        ))}
      </div>
    </div>
  );
};

export default Programs;
