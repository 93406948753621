import { useNavigate } from 'react-router-dom';
import { TGetInviteNotification } from 'src/services/apiEndpoint.types.ts';
import { Typography } from 'src/components/common';
import { colors } from 'src/constants';

import { TNotificationAction, TNotificationTypes } from '../notifications.types';

const useGetNotificationTItle = () => {
  // Hooks
  const navigate = useNavigate();

  const viewProfile = ({ userId }: { userId: string }) => {
    navigate(`/view-profile?id=${userId}`);
  };

  const viewCompanyProfile = ({ companyId }: { companyId: string }) => {
    navigate(`/view-company?id=${companyId}`);
  };

  const getNotificationTitle = ({ notification }: { notification: TGetInviteNotification }) => {
    const type: TNotificationTypes = notification?.metadata?.notificationType;
    const action: TNotificationAction = notification?.actionTaken;
    const isEndUser = notification?.metadata?.isEndUser;

    if (type === 'CollaborationRequest') {
      const ideaTitle: string = notification?.idea?.title;

      if (action === 'Pending') {
        const title = (
          <>
            <Typography
              as="span"
              variant="body-2"
              fontWeight="semiBold"
              style={{ color: colors?.primary?.[600], cursor: 'pointer' }}
              onClick={() => {
                viewProfile({ userId: notification?.user?.id });
              }}
            >
              {notification?.user?.name}
            </Typography>
            &nbsp;has invited you as a collaborator for &ldquo;{ideaTitle}&rdquo;
          </>
        );
        const description = notification?.idea?.description;
        return { title, description };
      } else if (action === 'Accepted' || action === 'Complete') {
        if (isEndUser) {
          const title = <>Collaboration invite accepted for &ldquo;{ideaTitle}&rdquo;</>;
          const description = `You've accepted the invitation from ${notification?.user?.name} to be a collaborator in their idea. Welcome to the team!`;
          return { title, description };
        } else {
          const title = `${notification?.user?.name} has accepted the collaboration invite for "${ideaTitle}"`;
          const description = `${notification?.user?.name} is now a collaborator on this idea`;
          return { title, description };
        }
      } else if (action === 'Rejected') {
        if (isEndUser) {
          const title = <>Collaboration invite rejected for &ldquo;{ideaTitle}&rdquo;</>;
          const description = `You've rejected the invitation from ${notification?.user?.name} to be a collaborator in their idea.`;
          return { title, description };
        } else {
          const title = `${notification?.user?.name} has rejected the collaboration invite for "${ideaTitle}"`;
          const description = `${notification?.user?.name} has declined the invite to collaborate on this idea`;
          return { title, description };
        }
      }
    } else if (type === 'EvaluationRequest') {
      const ideaTitle: string = notification?.idea?.title;

      if (action === 'Pending') {
        const title = (
          <>
            <Typography
              as="span"
              variant="body-2"
              fontWeight="semiBold"
              style={{ color: colors?.primary?.[600], cursor: 'pointer' }}
              onClick={() => {
                viewProfile({ userId: notification?.user?.id });
              }}
            >
              {notification?.user?.name}
            </Typography>
            &nbsp;has invited you as an evaluator for &ldquo;{ideaTitle}&rdquo;
          </>
        );
        const description = notification?.idea?.description;
        return { title, description };
      } else if (action === 'Accepted' || action === 'Complete') {
        if (isEndUser) {
          const title = <>Evaluation invite accepted for {ideaTitle}</>;
          const description = `You've accepted the invitation from ${notification?.user?.name} to be an evaluator in the idea. Welcome to the team!`;
          return { title, description };
        } else {
          const title = `${notification?.user?.name} has accepted the evaluation invite for “${notification?.idea?.title}”`;
          const description = `${notification?.user?.name} is now an evaluator on this idea`;
          return { title, description };
        }
      } else if (action === 'Rejected') {
        if (isEndUser) {
          const title = `Evaluation invite rejected for “${ideaTitle}”!`;
          const description = `You've rejected the invitation from ${notification?.user?.name} to be an evaluator in their Idea.`;
          return { title, description };
        } else {
          const title = `${notification?.user?.name} has rejected the evaluation invite for “${ideaTitle}”!`;
          const description = `${notification?.user?.name} has declined the invite to evaluate on this idea.`;
          return { title, description };
        }
      }
    } else if (type === 'KeyMemberRequest') {
      const companyName: string = notification?.company?.name;

      if (action === 'Pending') {
        const title = (
          <>
            <Typography
              as="span"
              variant="body-2"
              fontWeight="semiBold"
              style={{ color: colors?.primary?.[600], cursor: 'pointer' }}
              onClick={() => {
                viewCompanyProfile({ companyId: notification?.company?.id });
              }}
            >
              {notification?.company?.name}
            </Typography>
            &nbsp; has invited you to be a key member of their page
          </>
        );
        const description = `Accept the invite below to join ${companyName} as a key member of their page`;
        return { title, description };
      } else if (action === 'Accepted' || action === 'Complete') {
        if (isEndUser) {
          const title = <>Key member invite accepted!</>;
          const description = `You've accepted the invitation from  ${companyName} to be a key member. Welcome to the team!`;
          return { title, description };
        } else {
          const title = <>Key member invite accepted!</>;
          const description = `${notification?.user?.name} has accepted your invitation to join as a key member.`;
          return { title, description };
        }
      } else if (action === 'Rejected') {
        if (isEndUser) {
          const title = <>Key member invite rejected!</>;
          const description = `You've rejected the invitation from  ${companyName} to be a key member.`;
          return { title, description };
        } else {
          const title = <>Key member invite rejected!</>;
          const description = `${notification?.user?.name} has declined your invitation to join as a key member.`;
          return { title, description };
        }
      }
    }
    if (type === 'DemoInvite') {
      const companyName: string = notification?.company?.name || '';
      const programName = notification?.program?.title || '';
      const demoDate = notification?.demo?.demoDate
        ? new Date(notification?.demo?.demoDate)?.toDateString()
        : '';
      const demoTime = notification?.demo?.demoTime || '';

      const title = `${companyName} has sent a demo invitation for your application to the program ${programName}`;
      const description = `${companyName} has invited you for a demo on ${demoDate} at ${demoTime}. You can join using the link below. Details have also been sent to your email address`;

      return { title, description };
    }

    return { title: '', description: '' };
  };

  return getNotificationTitle;
};

export default useGetNotificationTItle;
