import { useContext } from 'react';
import { SelfCompanyContext, editCompanyModalId } from 'src/pages/myCompany/MyCompany';
// import Button from 'src/components/button/Button';
import {
  Card,
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Tooltip,
  Typography
} from 'src/components/common';
import { MoreOptionsIcon } from 'src/components/common/common.icons';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { StorageUtils, textEllipsis, textFormatter } from 'src/utils';
import ManageProfilePicture from 'src/components/common/profile/manageProfilePicture/ManageProfilePicture';
import {
  deleteCompanyProfilePicture,
  getCompanyPresignedUrl
} from 'src/store/company/company.slice';
import {
  TDeleteCompanyProfilePictureRequest,
  TGetCompanyPresignedUrlActionRequest
} from 'src/services/apiEndpoint.types.ts';
// import { useGetIdentity, useOpenInNewTab } from 'src/hooks';
// import { follow, unFollow } from 'src/store/socialMedia/socialMedia.slice';

import './companyInfoCard.scss';
import EditCompany from '../editCompany/EditCompany';

export type TCompanyProfileCard = {
  viewCompanyId?: string
  name: string;
  type: string;
  introduction?: string;
  website?: string;
  contactUsUrl?: string;
  profilePic?: string;
  dropdownItems: TDropdownOptions[];
  getUserStats?: ({ id }: { id: string }) => void;
};

const CompanyInfoCard = ({
  viewCompanyId,
  name = '-',
  type = '-',
  introduction = '-',
  website = ' ',
  contactUsUrl = ' ',
  profilePic = '-',
  dropdownItems,
  getUserStats
}: TCompanyProfileCard) => {
  const dispatch = useAppDispatch();

  const { dropdownId, modalId } = useAppSelector((store) => store.common);
  // const { getIdentities } = useGetIdentity();
  // const {   isFollowing } =
  //   useAppSelector((store) => store.socialMedia.userStats);

  // const { openInNewTab } = useOpenInNewTab();

  // Constants
  // const { authenticatedId, loggedInUserType } = getIdentities();

  const isSelfCompany = useContext(SelfCompanyContext); // Whether this profile card is viewed by self.

  const companyId = StorageUtils.get('companyId') as string

  // Utils
  const companyNameWitEllipsis = textEllipsis({ text: name, charLimit: 30 });

  const dropdownOptions: TDropdown = {
    id: 'company-profile-card-dropdown',
    size: 'large',
    alignment: 'center',
    items: dropdownItems
  };

  const openProfileCardDropdown = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(dropdownOptions.id));
    }
  };

  // const  = [
  //   { key: 'follower', label: 'Follower', value: followerCount },
  //   { key: 'following', label: 'Following', value: followingCount },
  //   { key: 'post', label: 'Post', value: postCount },
  //   { key: 'announcements', label: 'Announcements', value: announcementCount || 0 }
  // ];

  // const handleRedirectionToUrl = ({ url }: { url: string }) => {
  //   if (url) {
  //     openInNewTab({ url });
  //   }
  // };

  const handleUploadPicture = ({ data }: { data: string | ArrayBuffer | null }) => {
    const payload: TGetCompanyPresignedUrlActionRequest = {
      id: companyId,
      data,
      showPopup: true
    };

    dispatch(getCompanyPresignedUrl(payload));
  };

  const handleDeletePicture = () => {
    const payload: TDeleteCompanyProfilePictureRequest = { id: companyId };

    dispatch(deleteCompanyProfilePicture(payload));
  };

  // const handleFollow = () => {
  //   const payload: TFollowRequest = {
  //     userId: authenticatedId,
  //     userType: loggedInUserType,
  //     followUserId: viewCompanyId || 0,
  //     followUserType: 'Company'
  //   };

  return (
    <>
      <Card className="company-profile-card">
        <div className="company-profile-card__row">
          <div className="company-profile-card__row__picture">
            <ManageProfilePicture
              profilePic={profilePic || ''}
              name={name}
              isSelfUser={isSelfCompany}
              uploadFunc={handleUploadPicture}
              deleteFunc={handleDeletePicture}
            />
          </div>
          <div className="company-profile-card__row__content">
            <div className="company-profile-card__row__content__header">
              <div className="company-profile-card__row__content__header__company-info">
                <Tooltip
                  text={name}
                  conditionToDisplay={companyNameWitEllipsis?.hasEllipsis}
                  limitWidth
                >
                  <Typography as="h1" variant="subHeading-2" fontWeight="semiBold" style={{ fontSize: '35px' }}>
                    {textFormatter({ value: companyNameWitEllipsis?.text })}
                  </Typography>
                </Tooltip>
                <Typography as="p" variant="body-2" fontWeight="regular">
                  {/* {textFormatter({ value: type })} */}
                </Typography>
              </div>
              <div className="company-profile-card__row__content__header__actions">
                <>
                  <div
                    className="post-card__header__actions__dropdown"
                    onClick={(e) => {
                      e.stopPropagation();
                      openProfileCardDropdown();
                    }}
                  >
                    {<MoreOptionsIcon />}{' '}
                    <SubMenuModal>
                      <Dropdown {...dropdownOptions} />
                    </SubMenuModal>
                  </div>
                </>
              </div>
            </div>
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="company-profile-card__row__content__introduction"
            >
              {introduction}
            </Typography>
            {/* <div className="company-profile-card__row__content__stats">
              {.map(({ key, label, value }) => {
                return (
                  <div key={key} className="company-profile-card__row__content__stats__stat">
                    <div className="company-profile-card__row__content__stats__stat__values">
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                        className="company-profile-card__row__content__stats__stat__values__label"
                      >
                        {label}
                      </Typography>
                      <Typography
                        as="p"
                        variant="body-1"
                        fontWeight="semiBold"
                        className="company-profile-card__row__content__stats__stat__values__value"
                      >
                        {value || 0}
                      </Typography>
                    </div>
                    <hr></hr>
                  </div>
                );
              })}
            </div> */}
          </div>
        </div>
        {/* <div className="company-profile-card__footer">
          {!isSelfCompany && (
            <Button
              variant={isFollowing ? 'secondary' : 'primary'}
              size="small"
              className="company-profile-card__footer__button"
              onClick={handleFollow}
            >
              {isFollowing ? 'Following' : 'Follow'}
            </Button>
          )}
          {website && website.trim() && (
            <Button
              variant={isSelfCompany ? 'primary' : 'secondary'}
              size="small"
              className="company-profile-card__footer__button"
              onClick={() => {
                handleRedirectionToUrl({ url: website.trim() });
              }}
            >
              Website
            </Button>
          )}
          {contactUsUrl && contactUsUrl.trim() && (
            <Button
              variant={isSelfCompany ? 'secondary' : 'tertiary'}
              size="small"
              className="company-profile-card__footer__button"
              onClick={() => {
                handleRedirectionToUrl({ url: contactUsUrl.trim() });
              }}
            >
              Contact us
            </Button>
          )}
        </div> */}
      </Card>
      {isSelfCompany && modalId === editCompanyModalId && (
        <EditCompany isModalOpen={modalId === editCompanyModalId} />
      )}
    </>
  );
};

export default CompanyInfoCard;
