import { ChangeEvent, useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import Typography from 'src/components/common/typography/Typography';
import Card from 'src/components/common/card/Card';
import { SelfUserContext } from 'src/pages/myProfile/MyProfile';
import { textFormatter } from 'src/utils';
import { ChatIcon, EditIcon, MoreOptionsIcon } from 'src/components/common/common.icons';
import {
  DropdownOptionCard,
  TDropdownOptions,
  TDropdown,
  Dropdown,
  ActionIcon
} from 'src/components/common';
import SubMenuModal from 'src/components/common/subMenuModal/SubMenuModal';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  assignModalId,
  assignSubMenuModalId,
  openPopup,
  resetDropdownId
} from 'src/store/common/common.slice';
import { deleteProfilePicture, getPresignedUrl } from 'src/store/profile/profile.slice';
import { TFindCreateConversationRequest, TFollowRequest } from 'src/services/apiEndpoint.types.ts';
import { follow, unFollow } from 'src/store/socialMedia/socialMedia.slice';
import { Role } from 'src/constants/user.constants';
import { useGetIdentity } from 'src/hooks';
import { findCreateConversation } from 'src/store/chat/chat.slice';

import './profileCard.scss';

import EditProfile from '../editProfile/EditProfile';
import ProfilePicture from '../profilePicture/ProfilePicture';

export type TProfileCard = {
  introduction: string;
  name: string;
  profilePic: string;
  role: string;
  dropdownItems?: TDropdownOptions[];
  userId: string;
  getUserStats?: ({ id }: { id: string }) => void;
};

const editProfileModalId = 'edit-profile-modal';

const ProfileCard = ({
  introduction,
  name,
  profilePic,
  role,
  dropdownItems = [],
  userId,
  getUserStats
}: TProfileCard) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const uploadImageRef = useRef<HTMLInputElement>(null);

  const isSelfUser = useContext(SelfUserContext); // Whether this profile card is viewed by self.

  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getIdentities } = useGetIdentity();
  const { dropdownId, modalId } = useAppSelector((store) => store.common);
  const { followerCount, followingCount, postCount, isFollowing, ideaCount } = useAppSelector(
    (store) => store.socialMedia.userStats
  );
  const { profile } = useAppSelector((store) => store.profile);

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();

  const profileStats = [
    { key: 'follower', label: 'Follower', value: followerCount },
    { key: 'following', label: 'Following', value: followingCount },
    { key: 'post', label: 'Post', value: postCount },
    { key: 'ideas', label: 'Ideas', value: ideaCount }
  ];

  /* eslint-disable no-unused-vars */
  enum ImageFileTypes {
    BMP = 'bmp',
    JPG = 'jpg',
    JPEG = 'jpeg',
    PNG = 'png'
  }

  const openPictureDropdown = () => {
    if (dropdownId) {
      dispatch(resetDropdownId());
    } else {
      dispatch(assignSubMenuModalId('profile-card'));
    }
  };

  const openFileManager = async () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const handleOpenModal = () => {
    dispatch(assignModalId(editProfileModalId));
    setIsModalOpen(true);
  };

  const handleUploadProfilePicture = async (e: ChangeEvent<HTMLInputElement>) => {
    const file: File = (e.target.files as FileList)[0];
    if (file) {
      const { name } = file;
      const fileType = name.split('.').pop() as unknown as 'bmp' | 'jpg' | 'jpeg' | 'png';

      const selectedImageDetail = {
        type: fileType
      };

      const isImageFileType = Object.keys(ImageFileTypes).includes(
        selectedImageDetail.type.toUpperCase()
      );

      if (isImageFileType) {
        // upload image
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = async () => {
          const data = reader.result;

          dispatch(getPresignedUrl({ email: profile.email, data }));
        };
      } else if (!isImageFileType) {
        dispatch(
          openPopup({
            popupMessage: 'Image should be of type BMP, JPG, JPEG, or PNG',
            popupType: 'error'
          })
        );
      }
    }
    // Resetting the value of target
    const element = e.target as HTMLInputElement;
    element.value = '';
  };

  const handleDeleteProfilePicture = () => {
    dispatch(deleteProfilePicture({ email: profile.email }));
  };

  const dropdownOptions: TDropdown = {
    id: 'profile-card-dropdown',
    size: 'large',
    alignment: 'center',
    items: dropdownItems
  };

  const openProfileCardDropdown = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(dropdownOptions.id));
    }
  };

  const handleFollow = () => {
    const payload: TFollowRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      followUserId: userId,
      followUserType: 'User'
    };

    if (isFollowing) {
      dispatch(unFollow(payload)).then(() => {
        if (getUserStats) {
          getUserStats({ id: userId });
        }
      });
    } else {
      dispatch(follow(payload)).then(() => {
        if (getUserStats) {
          getUserStats({ id: userId });
        }
      });
    }
  };

  const chatToUser = () => {
    if (!isSelfUser && userId) {
      const payload: TFindCreateConversationRequest = {
        senderUserId: authenticatedId,
        senderUserType: loggedInUserType,
        receiverUserId: userId,
        receiverUserType: 'User'
      };

      dispatch(findCreateConversation(payload)).then((data) => {
        const conversationId = data?.conversation?.id;
        if (conversationId) {
          navigate(`/chat?conversationId=${conversationId}&userType=User&userId=${userId}`);
        }
      });
    }
  };

  return (
    <>
      <Card className="profile-card">
        <div className="profile-card__picture">
          <ProfilePicture url={profilePic} fallback={textFormatter({ value: name[0] })} />
          {isSelfUser && (
            <div
              className="profile-card__picture__edit"
              onClick={(e) => {
                e.stopPropagation();
                openPictureDropdown();
              }}
            >
              <EditIcon />
            </div>
          )}
          <SubMenuModal>
            <div
              data-modal="profile-card"
              className={classNames('profile-card__picture__options', {
                'profile-card__picture__options--is-open': dropdownId === 'profile-card'
              })}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(assignSubMenuModalId('profile-card'));
              }}
            >
              <DropdownOptionCard size="large" onClick={openFileManager}>
                Upload
              </DropdownOptionCard>
              <DropdownOptionCard size="large" onClick={handleDeleteProfilePicture}>
                Delete
              </DropdownOptionCard>
            </div>
          </SubMenuModal>
          <input
            type="file"
            hidden
            data-testid="inputUploadImage"
            ref={uploadImageRef}
            accept=".bmp,.jpg,.jpeg,.png"
            onChange={handleUploadProfilePicture}
          />
        </div>
        <div className="profile-card__content">
          <div className="profile-card__content__header">
            <div className="profile-card__content__header__user-info">
              <Typography as="h5" variant="subHeading-2" fontWeight="semiBold">
                {textFormatter({ value: name })}
              </Typography>
              <Typography as="p" variant="body-2" fontWeight="regular">
                {role === Role.SERVICE_PROVIDERS_STARTUP ? '-' : textFormatter({ value: role })}
              </Typography>
            </div>
            <div className="profile-card__content__header__actions">
              {isSelfUser && (
                <Button variant="secondary" size="extraSmall" onClick={handleOpenModal}>
                  Edit profile
                </Button>
              )}
              {!isSelfUser && (
                <>
                  <Button
                    variant={isFollowing ? 'secondary' : 'primary'}
                    size="extraSmall"
                    className="profile-card__content__header__actions__follow"
                    onClick={handleFollow}
                  >
                    {isFollowing ? 'Following' : 'Follow'}
                  </Button>{' '}
                  <ActionIcon
                    className="profile-card__content__header__actions__chat"
                    onClick={chatToUser}
                  >
                    <ChatIcon />
                  </ActionIcon>
                  <div
                    className="post-card__header__actions__dropdown"
                    onClick={(e) => {
                      e.stopPropagation();
                      openProfileCardDropdown();
                    }}
                  >
                    {<MoreOptionsIcon />}{' '}
                    <SubMenuModal>
                      <Dropdown {...dropdownOptions} />
                    </SubMenuModal>
                  </div>
                </>
              )}
            </div>
          </div>
          <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            className="profile-card__content__introduction"
          >
            {introduction}
          </Typography>
          <div className="profile-card__content__stats">
            {profileStats.map(({ key, label, value }) => {
              return (
                <div key={key} className="profile-card__content__stats__stat">
                  <div className="profile-card__content__stats__stat__values">
                    <Typography
                      as="p"
                      variant="caption"
                      fontWeight="regular"
                      className="profile-card__content__stats__stat__values__label"
                    >
                      {label}
                    </Typography>
                    <Typography
                      as="p"
                      variant="body-1"
                      fontWeight="semiBold"
                      className="profile-card__content__stats__stat__values__value"
                    >
                      {value || 0}
                    </Typography>
                  </div>
                  <hr></hr>
                </div>
              );
            })}
          </div>
        </div>
      </Card>
      {/*
       ** Edit profile modal
       */}
      {isSelfUser && modalId === editProfileModalId && (
        <EditProfile isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      )}
    </>
  );
};

export default ProfileCard;
