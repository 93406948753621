import { useEffect, useRef, useState, useMemo } from 'react';
import { Form, Formik } from 'formik';
import Button from 'src/components/button/Button';
import { Card, Separator, Typography } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { CalendarIcon, PlusIcon } from 'src/components/common/common.icons';
import { useToggleOpenModal, useUrlParamValue } from 'src/hooks';
import { useAppSelector } from 'src/store/hooks';
import { getUuid } from 'src/utils';
import { cancelCreateProgramModalId } from 'src/pages/createProgram/CreateProgram';

import './programDetails.scss';
import {
  TCustomField,
  TFieldType,
  TProgramDetailsField,
  TProgramDetailsInitialValues,
  TProgramDetailsProps,
  TProgramDetailsRequestPayload
} from './programDetails.types';
import AddEditProgramDetailsFields from './addEditProgramDetailsFields/AddEditProgramDetailsFields';
import DynamicField from './dynamicField/DynamicField';
import { defaultErrors } from './programDetails.data';
import createProgramDetailsSchema from './createProgramDetailsSchema';
import validateForm from './validateForm';
import useUpdateErrors from './hooks/useUpdateErrors';

const addProgramDetailsFieldsId = 'addProgramDetailsFields';
const editProgramDetailsFieldsId = 'editProgramDetailsFields';
const confirmDeleteFieldModalId = 'confirmDeleteFieldModal';

const ProgramDetails = ({
  shortlistStages,
  updateShortlistStages,
  customFields,
  setCustomFields,
  setProgramDraft,
  programDetailsFormikValues,
  continueToCustomApplication
}: TProgramDetailsProps) => {
  // Hooks
  const modalId: string = useAppSelector((store) => store.common.modalId);
  const toggleOpenModal = useToggleOpenModal();
  const { paramValue } = useUrlParamValue();
  const programListParams = useMemo(() => {
    const programTitle: any = (paramValue({ paramName: 'Draft' }))
    // const filterProgram: TFilterProgram = (paramValue({ paramName: 'filterProgram' }) ||
    //   'All') as TFilterProgram;
    return { programTitle };
  }, [window.location.search]);
  // State
  const [editFieldModalData, setEditFieldModalData] = useState<{
    id: string;
    fieldType: TFieldType;
  }>({ id: '', fieldType: 'label' });
  const startDateRef = useRef<HTMLInputElement[]>([]);
  const endDateRef = useRef<HTMLInputElement[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>(defaultErrors);
  const [showErrorsOnChange, setShowErrorsOnChange] = useState<boolean>(false);

  const updateErrors = ({ errors }: { errors: Record<string, string> }) => {
    setErrors(errors);
  };

  // Constants
  const isAddProgramDetailsFieldsOpen = modalId === addProgramDetailsFieldsId;
  const isEditProgramDetailsFieldsOpen = modalId === editProgramDetailsFieldsId;

  // Update errors hook
  const updateErrorsHook = useUpdateErrors();

  const updateErrorsOnChange = ({ values }: { values: TProgramDetailsInitialValues }) => {
    updateErrorsHook({ values, customFields, updateErrorsState: updateErrors });
  };

  // const initialValues: TProgramDetailsInitialValues = programDetailsFormikValues || {
  //   title: '',
  //   startDate: '',
  //   endDate: '',
  //   stages: NaN,
  //   stageTitles: []
  // };
  const [initialValues, setInitialValues] = useState<TProgramDetailsInitialValues>({
    title: '',
    startDate: '',
    endDate: '',
    stages: NaN,
    stageTitles: []
  });
  useEffect(() => {
    const { programTitle } = programListParams;
    console.log(programTitle, 'programTitle')
    const storedData = localStorage.getItem('programDetailsFormValues');
    if (storedData) {
      const programDetails = JSON.parse(storedData);
      setInitialValues(programDetails);
      console.log(programDetails, 'localStorage');
    } else {
      console.log('No data found in localStorage');
    }
  }, []);
  let shortListStagesArray: any[];
  try {
    shortListStagesArray = [...new Array(shortlistStages).keys()];
  } catch {
    shortListStagesArray = [];
  }

  const handleToggleOpenAddFieldsModal = () => {
    toggleOpenModal({ modalId: addProgramDetailsFieldsId });
  };

  const handleToggleOpenEditFieldsModal = () => {
    toggleOpenModal({ modalId: editProgramDetailsFieldsId });
  };

  const handleToggleOpenDeleteFieldModal = () => {
    toggleOpenModal({ modalId: confirmDeleteFieldModalId });
  };

  const handleToggleOpenCancelCreateProgramModal = () => {
    toggleOpenModal({ modalId: cancelCreateProgramModalId });
  };

  const openChangeFieldModal = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setEditFieldModalData({ fieldType, id: id || '' });
    handleToggleOpenEditFieldsModal();
  };

  const changeFieldType = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setCustomFields((prev) => {
      const currentFields: TCustomField[] = JSON.parse(JSON.stringify(prev));
      const selectedField = currentFields.find((field) => field.id === id);

      if (selectedField) {
        selectedField.type = fieldType;
      }

      return currentFields;
    });
    setEditFieldModalData({ id: '', fieldType: 'label' });
    handleToggleOpenEditFieldsModal();
  };

  const handleAddField = ({ fieldType }: { fieldType: TFieldType }) => {
    handleToggleOpenAddFieldsModal();

    const newId = getUuid();

    const newField: TCustomField = {
      id: newId,
      type: fieldType,
      label: '',
      placeholder: '',
      values: '',
      files: []
    };

    setCustomFields((prev) => prev.concat(newField));
  };

  const handleDeleteField = ({ id }: { id: string }) => {
    if (id) {
      setCustomFields((prev) => {
        const currentFields: TCustomField[] = JSON.parse(JSON.stringify(prev));
        const selectedFieldIndex: number = currentFields.findIndex((field) => field.id === id);

        if (selectedFieldIndex > -1) {
          currentFields.splice(selectedFieldIndex, 1);
        }

        return currentFields;
      });

      handleToggleOpenDeleteFieldModal();
    }
  };

  const handleSubmit = async (values: TProgramDetailsInitialValues) => {
    setShowErrorsOnChange(true);

    const { title, startDate, endDate, stages, stageTitles, ...programDetailsFields } = values;
    console.log(values, 'vluaaaaaaaaaaa')
    setProgramDraft(values)
    // localStorage.setItem('programDetailsFormValues', JSON.stringify(values));

    const programDetails: TProgramDetailsField[] = [];

    try {
      Object.keys(programDetailsFields).forEach((key) => {
        const field = programDetailsFields[key as keyof typeof programDetailsFields];
        programDetails.push(field);
      });
    } catch {}

    const programDetailsValues: TProgramDetailsRequestPayload = {
      title,
      startDate,
      endDate,
      stages,
      stageTitles,
      programDetails
    };

    const customFieldValues: Record<string, string> = {};

    Object.keys(programDetailsFields).forEach((fieldKey) => {
      const field: TCustomField =
        programDetailsFields[fieldKey as keyof typeof programDetailsFields];
      const id: string = field?.id;

      customFieldValues[id] = field?.values || '';

      if (field?.type !== 'label') {
        customFieldValues[`${id}-label`] = field?.label || '';
      }
    });

    const schema = createProgramDetailsSchema({ fields: customFields });

    const errors = await validateForm({
      values: { title, startDate, endDate, stages, ...customFieldValues },
      schema,
      updateErrors
    });

    const hasZeroErrors: boolean = errors?.length < 1;

    if (hasZeroErrors) {
      continueToCustomApplication({ values: programDetailsValues, formikValues: values });
    }
  };

  return (
    <div className="program-details">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
        {({ values, setFieldValue }) => {
          // Update errors on change of values
          // only after continue button is clicker (showErrorsOnChange)
          useEffect(() => {
            if (showErrorsOnChange) {
              updateErrorsOnChange({ values });
            }
          }, [values]);
          return (
            <Form className="program-details__form">
              <div className="program-details__form__header">
                <Typography as="span" variant="body-2" fontWeight="semiBold">
                  Program Details
                </Typography>
                <div className="program-details__form__header__navigation">
                  <Button
                    size="extraSmall"
                    variant="secondary"
                    type="button"
                    onClick={handleToggleOpenCancelCreateProgramModal}
                  >
                    Cancel
                  </Button>
                  <Button size="extraSmall" variant="primary" type="submit">
                    Continue
                  </Button>
                </div>
              </div>
              <Typography as="p" variant="body-2" fontWeight="regular">
                We&apos;ve simplified your program details entry! Begin with our suggested fields
                for a quick and easy way to enter information. Feel free to customize it according
                to your needs.
              </Typography>
              <Card>
                <InputField
                  label="Enter Program Title"
                  id="title"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Program Title"
                  type="text"
                  required
                  errorMessage={errors?.title}
                />
              </Card>
              <div className="program-details__form__row">
                <Card className="program-details__form__row__field">
                  <InputField
                    label="Select start date"
                    id="startDate"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Select date"
                    type="date"
                    inputRef={(el: HTMLInputElement) => {
                      startDateRef.current[0] = el;
                    }}
                    endIconClickHandler={() => {
                      if (startDateRef.current) {
                        startDateRef.current[0].showPicker();
                      }
                    }}
                    onChange={(e) => {
                      setFieldValue('startDate', e.target.value);
                    }}
                    endIcon={<CalendarIcon />}
                    required
                    errorMessage={errors?.startDate}
                  />
                </Card>
                <Card className="program-details__form__row__field">
                  <InputField
                    label="Select end date"
                    id="endDate"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Select date"
                    type="date"
                    inputRef={(el: HTMLInputElement) => {
                      endDateRef.current[0] = el;
                    }}
                    endIconClickHandler={() => {
                      if (endDateRef.current) {
                        endDateRef.current[0].showPicker();
                      }
                    }}
                    onChange={(e) => {
                      setFieldValue('endDate', e.target.value);
                    }}
                    endIcon={<CalendarIcon />}
                    required
                    errorMessage={errors?.endDate}
                  />
                </Card>
              </div>

              {/* Shortlist stages */}
              <Card className="program-details__form__stages">
                <InputField
                  label={
                    <>
                      {' '}
                      <Typography as="span" variant="caption" fontWeight="semiBold">
                        Specify the number of shortlist stages desired for the shortlisting
                        applications.
                      </Typography>
                      {'  '}
                      <Typography as="span" variant="caption" fontWeight="regular">
                        (Max: 5 stages)
                      </Typography>
                    </>
                  }
                  id="stages"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Ex. 4"
                  type="number"
                  onChange={(e) => {
                    const value = parseInt(e.target.value);

                    if (value <= 5 && value >= 0) {
                      updateShortlistStages({ value });
                      setFieldValue('stages', value);
                    } else {
                      updateShortlistStages({ value: NaN });
                      setFieldValue('stages', NaN);
                    }
                  }}
                  required
                  errorMessage={errors?.stages}
                />
                {shortListStagesArray?.length > 0 && <Separator />}
                {shortListStagesArray.map((num) => {
                  const stageNumber: string = num + 1;

                  return (
                    <InputField
                      key={num}
                      label={`Stage ${stageNumber}`}
                      id={`stage${stageNumber}`}
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="Stage name"
                      type="text"
                      value={values.stageTitles?.[num]}
                      onChange={(e) => {
                        const currentFields: string[] = JSON.parse(
                          JSON.stringify(values?.stageTitles)
                        );
                        currentFields[num] = e.target.value;
                        setFieldValue('stageTitles', currentFields);
                      }}
                    />
                  );
                })}
              </Card>

              {/* Custom fields */}
              {customFields.map((field: TCustomField, index) => {
                return (
                  <DynamicField
                    {...field}
                    openChangeFieldModal={openChangeFieldModal}
                    deleteField={handleDeleteField}
                    setCustomFields={setCustomFields}
                    key={index}
                    errors={errors}
                  />
                );
              })}

              {/* Add field */}
              <Button
                size="small"
                variant="primary"
                startIcon={<PlusIcon />}
                className="program-details__form__add-field"
                onClick={handleToggleOpenAddFieldsModal}
              >
                Add Field
              </Button>
            </Form>
          );
        }}
      </Formik>
      {isAddProgramDetailsFieldsOpen && (
        <AddEditProgramDetailsFields
          isModalOpen={isAddProgramDetailsFieldsOpen}
          onModalClose={handleToggleOpenAddFieldsModal}
          title="Add Fields"
          onSelection={handleAddField}
        />
      )}
      {isEditProgramDetailsFieldsOpen && (
        <AddEditProgramDetailsFields
          isModalOpen={isEditProgramDetailsFieldsOpen}
          onModalClose={handleToggleOpenEditFieldsModal}
          title="Edit Fields"
          currentType={editFieldModalData.fieldType}
          id={editFieldModalData.id}
          onSelection={changeFieldType}
        />
      )}
    </div>
  );
};

export default ProgramDetails;
